.OrderCollectionParent {
  margin-top: 1rem;
}
.OrderCollectionContainer {
  background: #fffce6;
  border: 1px solid #ffdd02;
  box-sizing: border-box;
  border-radius: 0.9rem;
  padding: 1rem;

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;
    color: #424242;
  }
  .CollectionName {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #212121;
  }
  .CollectionActiveButton {
    padding: 1.0625rem 0;
    background: #ffdd02;
    border-radius: 0.6rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > p {
      text-align: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
      display: flex;
      align-items: center;
      color: #212121;
      margin: 0;
      height: 1px;
    }
    > svg {
      margin: 0;
      margin-right: 0.5rem;
    }
  }
}

.CollectionButtonComplete {
  padding: 1.0625rem 0;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1.5px solid #e0e0e0;
  > p {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    color: #212121;
    margin: 0;
  }
  > svg {
    margin-right: 0.5rem;
  }
}
