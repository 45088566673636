.orderHistoryViewContainer {
  padding: 1.875rem 2.5rem;
  overflow-y: auto;
  height: calc(100vh - 7.6875rem);
  position: relative;
  margin-top: 5rem;
  .orderDetails {
    padding-bottom: 1.875rem;
    border-bottom: 1px solid #e8eaf4;
    .orderRefAndStatus {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > div:first-child {
        width: 7.375rem;
        height: 4.375rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
      > div:last-child {
        width: 6.25rem;
        height: 2.5rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
    }
    .dateAndTime {
      display: flex;
      justify-content: space-between;
      margin-top: 1.875rem;
      div {
        width: 8.125rem;
        height: 1.25rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
    }
    .venueDetails {
      margin-top: 1.875rem;
      > div {
        height: 3.125rem;
        width: 100%;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
    }
    .timeSlotDetails {
      margin-top: 1.875rem;
      > div {
        height: 3.125rem;
        width: 6.875rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
    }
    .deliveryDetails {
      margin-top: 1.875rem;
      > div {
        height: 5.125rem;
        width: 100%;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
    }
  }
  .paymentDetails {
    padding: 1.875rem 0;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      div {
        height: 1.625rem;
        width: 6.25rem;
        margin-bottom: 1.3125rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          rect {
            width: 100%;
          }
        }
      }
    }
  }
  .productInfo {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-bottom: 1px solid #e8eaf4;
    div {
      height: 8.125rem;
      > svg {
        width: 100%;
        height: 100%;
        border-radius: 0.625rem;
        rect {
          width: 100%;
        }
      }
    }
  }
}
