.ExtraSearchDiv {
  width: 100%;
  position: relative;
  padding-bottom: 1rem;
  > span {
    position: absolute;
    font-size: 1.7rem;
    left: 0.7rem;
    top: 0.8rem;
    margin: auto;
    height: fit-content;
  }
}

.SearchModsInput {
  background: #f3f4f9;
  border-radius: 0.5rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #9e9e9e;
  width: 100%;
  padding: 1rem 1.2rem 1rem 2.8rem;
  border: none;
}
