@import "~antd/dist/antd.css";
html {
  scroll-behavior: smooth !important;
}

.top_menu {
  width: 100%;
  /* margin-top: 1.375rem; */
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.top_menu__filter-wrapper {
  padding: 16px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid #e8eaf4;
}
.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topMenuTabs {
  /* border-radius: 20px 20px 0 0; */
  /* border: 1px solid #ccc; */
  border-bottom: unset;
  height: 3rem;
  background-color: #fff;
  width: 100%;
  margin: auto;
}

.MenuSearchIcon {
  font-size: 2.4rem !important;
  z-index: 1;
}
.sub_cats {
  position: relative;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: unset;
  height: unset;
  opacity: unset;
  pointer-events: unset;
}
.ant-tabs-nav-container-scrolling {
  padding-right: unset;
  padding-left: unset;
}
.subMenu {
  /* border-radius: 0  0 20px 20px ; */
  /* border: 1px solid #ccc; */
  border-top: unset;
  height: 3.75rem;
  background-color: #fff;
}
.subMenu .ant-tabs-bar {
  border-bottom: unset !important;
}
.subMenu .ant-tabs-ink-bar {
  background-color: unset;
  display: none !important;
  height: 0 !important;
}
.subMenu .ant-tabs-tab span {
  padding: 0.3125rem 1.125rem;
  color: #8d98b7;
}
.topMenuTabs .ant-tabs-tab span {
  color: #757575;
}
.subMenu .ant-tabs-tab-active span {
  color: #212121;
  font-size: 0.9375rem;
  background: #ffffff;
  box-shadow: 0px 0.25rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}
.topMenuTabs .ant-tabs-tab-active span {
  color: #212121;
  border-radius: 0.375rem;
  font-size: 1rem;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #212121 !important ;
}
.ant-tabs-nav {
  width: 100%;
}
.ant-tabs-nav .ant-tabs-tab {
  padding: 0.75rem 1rem;
  margin: 0;
}
.searchInput {
  height: 2.875rem;
  flex: 1 1 auto;
}
/* .ant-tabs-nav .ant-tabs-tab:hover{
  color: unset !important
} */
/* .ant-tabs-nav .ant-tabs-tab:active{
  color: unset !important

} */
.searchInput .SearchInput {
  height: 100%;
  padding: 0 0.75rem;
  border-radius: 0.4375rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: #48a3f8;
}

.ant-tabs-tab-next-icon {
  display: none !important;
}

.ant-tabs-tab-prev-icon {
  display: none !important;
}

@media (max-width: 424px) {
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0.75rem 0.875rem;
  }
}
@media (max-width: 320px) {
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0.75rem 0.5rem;
  }
}
.top_menu .ant-tabs-nav {
  display: flex;
}
.top_menu .ant-tabs-nav .ant-tabs-tab {
  flex-grow: 1;
  margin-right: 0px;
  width: 100%;
  text-align: center;
}
.subMenu .ant-tabs-nav .ant-tabs-tab {
  width: fit-content;
  text-align: center;
}

.animate {
  transform: unset !important;
}
.top_menu .ant-tabs-nav-animated > div {
  display: flex;
  width: 100%;
  height: 4.0625rem;
}
.top_menu .ant-tabs-bar {
  border-bottom: unset;
}
.subMenu .ant-tabs-nav-animated {
  transform: none !important;
  overflow-x: auto;
}
.topMenuTabs .ant-tabs-nav-animated {
  transform: none !important;
  overflow-x: auto;
}
.ant-tabs-tab:hover {
  color: unset !important;
}
.ant-tabs-ink-bar {
  height: 1.25rem !important;
  background-color: #ffdd02;
}
/* .menu_contents {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
} */
.menu_contents {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.menu_contents .loadingContainer {
  height: 50vh !important;
}
.menu_contents > div .LottieLoading {
  width: 20%;
}

.actived {
  background: #212121 !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

.menu_list_wrapper {
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.shadowRight {
  position: absolute;
  width: 40px;
  height: 100%;
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0));
}
