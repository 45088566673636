.react-tel-input {
  background-color: #f9f9f9;
  border-radius: 0.625rem;
  border: none;
}

.react-tel-input input.form-control {
  background-color: #f9f9f9;
  border-radius: 0.625rem;
  height: 3.4375rem;
  padding-left: 3.75rem;
  width: 100%;
  border: none;

  font-family: Open Sans;
  font-style: normal;
  font-size: 1rem;
  color: #212121;
  box-shadow: none;
  border: 1px solid transparent;
  transition: 0.2s ease-in-out;
  &:focus {
    background-color: #fff;
    border: 1px solid #ffdd02;
  }
}

.flag-dropdown {
  margin: 0.625rem;
  background: #fff !important;
  border-radius: 0.5rem !important;
}

.selected-flag {
  background: transparent !important;
  padding: 0 0 0 8px !important;
  border-radius: 12px !important;
}
