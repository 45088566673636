.ShareWidget {
  width: 100%;
  background: #f9f9f9;
  border-radius: 1rem;
  padding: 1.25rem 1.5625rem;
  height: 100%;
}

.ShareWidget .ShareBoxDivs {
  margin: 0;
}

.ShareWidget .EnjoyText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  color: #616161;
  margin: 0;
}

.ShareWidget .ShareText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  text-align: left;
  color: #616161;
  margin-bottom: 1rem;
}

.ShareWidget .ShareItemDiv {
  background: transparent;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.75rem;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.ShareBoxDivs > a:hover {
  text-decoration: none;
}
.ShareWidget .ShareItemDiv span {
  font-size: 1.7rem;
}

.ShareWidget .ShareItemName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  text-align: center;
  color: #616161;
  mix-blend-mode: normal;
  text-transform: uppercase;
  margin: 0.3125rem 0;
  width: 3rem;
}

.ShareWidget .shareTextMarginBottom {
  margin-bottom: 0.3125rem;
}
.ShareWidget .SocialMediaWrapper {
  position: relative;
}
.ShareWidget .SocialMediaWrapper > div:last-child > div > button > svg {
  width: 3.125rem;
  height: 3.125rem;
}
.shareOnConfirmOrderPage > div:last-child {
  height: 3.75rem !important;
}
.shareOnConfirmOrderPage > div:last-child > div > button > svg {
  width: 2.8rem !important;
}
.shareOnConfirmOrderPage > div:first-child {
  position: unset !important;
}
.shareOnConfirmOrderPage > div:first-child > span {
  font-size: 1.5rem !important;
}
@media only screen and (max-height: 650px) {
  /* .ShareWidget .ShareText{
        font-size: 10px;
    }

    .ShareWidget .shareTextMarginBottom{
        margin-bottom: -5px;
    }
    .ShareWidget .ShareItemDiv{
        
    width: 42px;
    height: 42px;
    }
    .ShareWidget .ShareItemName{
        font-size: 8px;
    } */
}
