.tradingTimesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  :global(.ant-collapse) {
    background-color: unset;
    border: unset;
    width: 100%;
  }
  :global(.ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow) {
    right: 0;
    left: unset;
  }
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    position: relative;
    padding: 12px 0;
    /* padding-left: 40px; */
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #212121;
  }
  :global(.ant-collapse-content) {
    border-top: unset;
  }
  :global(.ant-collapse > .ant-collapse-item) {
    border-bottom: unset;
  }
  :global(.ant-collapse-content > .ant-collapse-content-box) {
    padding: 0;
  }
  ul {
    padding: 0;
    > li {
      list-style: none;
      padding: 0.625rem 0;
      border-bottom: 1px solid #e8eaf4;
    }
  }
  .worksDays {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    > div:first-child {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      color: #8d98b7;
    }
    > div:last-child {
      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: #8d98b7;
      }
    }
  }
  .active {
    > div:first-child {
      font-weight: 600;
      font-size: 1.0625rem;
      line-height: 1.4375rem;
      color: #212121;
    }
    > div:last-child {
      span {
        font-weight: 600;
        color: #212121;
      }
    }
  }
  // > div {
  //   width: 100%;
  // }
  // .workDaysWrapper {
  //   width: 100%;
  //   overflow: hidden;
  //   // height: 220px;
  //   overflow-y: auto;
  //   font-family: Open Sans;
  //   font-style: normal;
  //   font-weight: 700;
  //   font-size: 1.25rem;
  //   line-height: 3.125rem;
  //   text-align: center;
  //   color: #000;
  //   margin: auto;
  //   // transition: height 0.25s ease-out;
  //   > div:first-child {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-between;
  //     > span:first-child {
  //       font-family: Open Sans;
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 1rem;
  //       line-height: 1.25rem;
  //       color: #212121;
  //     }
  //     > span:last-child {
  //       font-family: Open Sans;
  //       font-style: normal;
  //       font-weight: normal;
  //       font-size: 0.9375rem;
  //       line-height: 1.25rem;
  //       color: #8d98b7;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       > span {
  //         font-size: 1.5rem;
  //       }
  //     }
  //   }
  //   .currentMonth {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: center;
  //     > span:first-child {
  //       padding-right: 0.625rem;
  //     }
  //   }
  // }
  // // .workDaysWrapper.open {
  // //   height: 300px;
  // //   transition: height 0.25s ease-in;
  // // }
  // .workDays {
  //   width: 100%;
  //   color: #ccc;
  //   font-size: 0.875rem;

  //   > div {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-around;
  //     font-family: Open Sans;
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 0.9375rem;
  //     line-height: 0;
  //     text-align: center;
  //     color: #8d98b7;
  //     margin-top: 0.5rem;
  //     > span {
  //       width: 2.6875rem;
  //       height: 2.5rem;
  //       line-height: 2.5rem;
  //     }
  //     > span.active {
  //       border-radius: 0.25rem 0.25rem 0 0;
  //       font-family: Open Sans;
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 1.0625rem;
  //       line-height: 0;
  //       display: flex;
  //       align-items: center;
  //       text-align: center;
  //       color: #212121;
  //       border-bottom: 2px solid #ffdd02;
  //       justify-content: center;
  //     }
  //   }
  //   > div:last-child {
  //     width: 100%;
  //     color: #fff;
  //     display: flex;
  //     flex-direction: column;
  //     > header {
  //       width: 100%;
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: space-between;
  //       background: #f9f9f9;
  //       border-radius: 0.5rem;
  //       margin-top: 0.625rem;
  //       height: 2.625rem;
  //       > span {
  //         font-family: Open Sans;
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 0.9375rem;
  //         line-height: 0;
  //         padding: 0 0.75rem;
  //         /* identical to box height, or 0% */
  //         display: flex;
  //         align-items: center;
  //         text-align: center;
  //         color: #757575;
  //       }
  //     }
  //     .divider {
  //       border-bottom: 1px solid #eeeeee;
  //     }
  //     > div {
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: space-between;
  //       background-color: #fff !important;
  //       color: black !important;
  //       height: 2.625rem;
  //       > span {
  //         font-family: Open Sans;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 1rem;
  //         line-height: 0;
  //         /* identical to box height, or 0% */
  //         display: flex;
  //         align-items: center;
  //         color: #212121;
  //         padding: 0 1.875rem;
  //       }
  //     }
  //     > p {
  //       font-family: Open Sans;
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 1rem;
  //       line-height: 0;
  //       /* identical to box height, or 0% */
  //       display: flex;
  //       align-items: center;
  //       color: #212121;
  //       padding: 0 1.875rem;
  //       height: 2.625rem;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   }
  // }
}
