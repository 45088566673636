.notFoundPageContainer {
  height: 100vh;
  background-color: #ffffff;
  overflow-y: auto;
  .sidemenuToggleBtn {
    top: 1.875rem;
    right: 1.875rem;
  }
  .wrapper {
    margin: 4.125rem 2.5rem 2.5rem 2.5rem;
    .yoelloIcon {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        width: 5rem;
        height: 5rem;
        > g {
          > path {
            fill: #ffde21 !important;
          }
        }
        > path {
          fill: #212121 !important;
        }
      }
    }
    .descriptions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        font-family: Open Sans;
        font-style: normal;
        color: #212121;
        padding-top: 1.5625rem;
      }
      > p:first-child {
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 1.875rem;
        display: flex;
        align-items: center;
        margin: 0;
      }
      > p:last-child {
        padding: 0 3.5625rem;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.875rem;
        display: flex;
        align-items: center;
        text-align: center;
        padding-top: 1.875rem !important;
        margin: 0;
      }
    }
    .qrCode {
      margin-top: 2.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .scanQrCode {
      margin-top: 1rem;
      > p {
        margin: 0;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.875rem;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #737fa4;
      }
    }
    .yoelloText {
      margin-top: 6.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bottomText {
      margin-top: 0.9375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
        display: flex;
        align-items: center;
        text-align: center;

        color: #aab6d3;
      }
    }
  }
}
