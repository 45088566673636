.NavigationHeader {
  width: 100%;
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4375rem 2.0625rem 0.6875rem 2.0625rem;
  flex: 0 0;
  background: white;
  z-index: 1;
}

.sidemenuToggleBtn {
  top: 1.125rem;
  right: 0.75rem;
}
.backBtn {
  top: 1.125rem;
  left: 0.75rem;
}
.headerConteiner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1rem;
  > img {
    width: 3.875rem;
    height: 3.875rem;
    border-radius: 0.25rem;
  }
  > svg {
    width: 3.875rem;
    height: 3.875rem;
    border-radius: 0.25rem;
  }
  > p {
    margin-left: 0.25rem;
    color: #212121;
    font-size: 1.1875rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.title {
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
}
