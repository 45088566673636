.groupPageContainer {
  width: 100%;
  height: 100vh;
  overflow: auto;
  font-size: 1rem;
  background-color: #212121;
  .header {
    height: 13.4375rem;
    position: relative;
    > svg {
      width: 100%;
      height: 100%;
      rect {
        width: 100%;
      }
    }
    .SideMenuDrawerBtn {
      top: 2.4375rem;
      right: 2.25rem;
      position: absolute;
      height: 2.875rem;
      width: 2.875rem;
      > svg {
        width: 100%;
        height: 100%;
        rect {
          width: 100%;
        }
      }
    }
    .NHSDrawerBtn {
      top: 2.4375rem;
      left: 2.25rem;
      position: absolute;
      height: 2.875rem;
      width: 2.875rem;
      > svg {
        width: 100%;
        height: 100%;
        rect {
          width: 100%;
        }
      }
    }
    .groupDetails {
      position: absolute;
      top: 2.5625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .groupLogo {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        position: absolute;
        width: 100%;
        > div {
          height: 2.5rem;
          width: 7rem;
          > svg {
            width: 100%;
            height: 100%;
            rect {
              width: 100%;
            }
          }
        }
      }
      > div:last-child {
        display: flex;
        padding-top: 5rem;
        justify-content: space-between;
        width: 100%;
        .groupName {
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 2.5rem;
          > p {
            height: 1.9375rem;
            width: 7.5rem;
            > svg {
              width: 100%;
              height: 100%;
              rect {
                width: 100%;
              }
            }
          }
        }
        .groupAddressAndShare {
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 2.5rem;
          > p {
            height: 1.375rem;
            width: 5.5rem;
            > svg {
              width: 100%;
              height: 100%;
              rect {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .content {
    position: absolute;
    width: 100%;
    background: #ffffff;
    border-radius: 2.25rem 2.25rem 0px 0px;
    top: 10.875rem;
    transition: all 350ms ease-out;
    max-width: 768px;
    .lineBar {
      height: 0.0625rem;
      margin: 4.3125rem 25px 0 25px;
      background: #e8eaf4;
      border-radius: 2.5625rem;
      > span {
        width: 100%;
        background: #e8eaf4;
        border-radius: 2.5625rem;
        height: 100%;
      }
    }
    .venuesList {
      margin: 1.9375rem 1.25rem;
      overflow-y: auto;
      height: 100%;
      > div {
        height: 6.25rem;
        margin-bottom: 1rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 1.125rem;
          rect {
            width: 100%;
          }
        }
      }
    }
  }
}
