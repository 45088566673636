@mixin border-bottom() {
  border-bottom: 1px solid #e8eaf4;
}

@mixin display-flex-Sbetween() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin texts-css($collor, $fontSize, $fontWeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $collor;
  margin: 0;
}

.selectTableContainer {
  height: calc(100vh - 7.5rem);
  display: flex;
  flex-direction: column;
  // padding: 0 1.5625rem 1.25rem 1.5625rem;
  background-color: #fff;

  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 1rem;
    padding: 0 1.5625rem 0 1.5625rem;
    > span {
      width: 2.625rem;
      background-color: #e8eaf4;
      height: 0.3125rem;
      position: absolute;
      top: 0.625rem;
    }
  }

  .DeliveryHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;
    padding-bottom: 0.4rem;

    > p {
      @include texts-css(#737fa4, 1.05rem, 600);
      text-align: center;
    }
    > div {
      position: absolute;
      right: 1rem;
    }
  }
  .selectTableText {
    @include texts-css(#212121, 1.0625rem, normal);
    padding: 1rem 2.5rem;
    margin-top: 0.5rem;
    > span {
      font-weight: 600;
    }
  }
  .searchInputContainer {
    width: 100%;
    padding: 0.9375rem 1.5625rem;
    // border-bottom: 1px solid #e8eaf4;
    position: relative;
    .searchInput {
      background: #f3f4f9;
      border-radius: 0.5rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      color: #9e9e9e;
      width: 100%;
      padding: 1rem 3.125rem;
      border: none;
      @include border-bottom();
    }
    > span {
      position: absolute;
      font-size: 2rem;
      left: 2.375rem;
      top: -1px;
      bottom: 0;
      margin: auto;
      height: -moz-fit-content;
      height: fit-content;
    }
  }
  .divider {
    border-bottom: 1px solid #e8eaf4;
    width: 86%;
    margin: auto;
  }
  .tablesListWrapper {
    height: calc(100vh - 21rem);
    overflow: auto;
    padding: 0 1.5625rem 0 1.5625rem;
    .tableRow {
      background: #f9f9f9;
      border-radius: 0.875rem;
      border-bottom: 0.70775px solid #ffffff;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      padding: 1rem 1.75rem;
      margin: 0.75rem 0;
      position: relative;
      height: 3.375rem;
    }
    .TableRowSelected {
      background: #faf6e0 !important;
      border: 1px solid #ffdd02 !important;
      box-sizing: border-box !important;
      border-radius: 0.625rem !important;
      .SelectedBoxLine {
        background: #ffdd02;
        border-radius: 0.75rem;
        width: 0.25rem;
        margin-right: -0.5rem;
        margin-left: 0.25rem;
        height: 1.25rem;
      }
    }

    :global(.ant-collapse-header) {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      display: flex;
      align-items: center;
      color: #212121;
      padding-left: 1.125rem !important;
      height: 3.75rem;
      > span {
        left: unset !important;
        right: 1.25rem;
        > svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    :global(.ant-collapse-content-box) {
      padding: unset;
    }
    :global(.ant-collapse-content) {
      border-top: unset;
    }
    :global(.ant-collapse) {
      border: unset !important;
      background-color: #fff;
    }
  }
  .btnWrapper {
    position: relative;
    bottom: 0;
    padding: 0 1.5625rem 1.25rem 1.5625rem;
  }
}
