.orderList {
  padding: 1.875rem 1.25rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 7.6875rem);
  position: relative;
  margin-top: 5rem;
  div {
    height: 7.5rem;
    margin-top: 0.9375rem;
    > svg {
      width: 100%;
      height: 100%;
      border-radius: 0.625rem;
      rect {
        width: 100%;
      }
    }
  }
}
