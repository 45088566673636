.tagsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.875rem 2.5rem;
  position: relative;
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #212121;
    margin-bottom: 2.5rem;
  }
  .InfoTitles {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    color: #616161;
    margin: 0;
  }
  .suitableTagsWrapper {
    width: 100%;
  }
  .warningWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    > div:first-child {
      display: flex;
      align-items: center;
      > svg {
        margin-right: 0.625rem;
      }
    }
  }
  .tags {
    padding: 0;
    padding-left: 1rem;
    margin: 1.75rem 0;
    > li {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      color: #424242;
    }
  }
  .InfoBottomText {
    background: #f1f9ff;
    border-radius: 0.875rem;
    bottom: 1.875rem;
    > div:first-child {
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      margin-top: 1.25rem;
      > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.25rem;
        color: #212121;
        margin: 0;
        padding-left: 0.625rem;
      }
      > i {
        color: #1d8bf7;
        font-size: 1.75rem;
      }
    }
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9375rem;
      line-height: 1.625rem;
      display: flex;
      align-items: center;
      color: #616161;
      margin: 0.625rem 1.625rem 1.25rem 1.625rem;
    }
  }
}
