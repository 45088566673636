$color: #65ff78;
.groupPageContainer {
  width: 100%;
  // height: 100vh;
  // overflow: auto;
  // -webkit-overflow-scrolling: touch;
  font-size: 1rem;
  background: #212121;
  // margin-bottom: 3rem;
  // padding-bottom: 3rem;
  .header {
    min-height: 10.4375rem;
    // background: #212121;
    position: relative;
    margin-bottom: 1.5625rem;
    .SideMenuDrawerBtn {
      top: 2.4375rem;
      right: 2.25rem;
      > svg {
        > line {
          stroke: #fff;
        }
      }
    }
    .NHSDrawerBtn {
      text-align: center;
      top: 2.4375rem;
      left: 2.25rem;
      width: 2.875rem;
      justify-content: center;
      font-size: 0.6875rem;
      font-weight: 600;
      line-height: 1rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      > p {
        margin-top: 1rem;
      }
    }
    .groupDetails {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2.5625rem;
      transition: all 300ms ease-in-out;
      // transition: all 350ms ease-out;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      .groupLogo {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 2.375rem;
        min-height: 1.875rem;
        margin-bottom: 2.1875rem;
        // transition: all 350ms ease-out;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        > img {
          max-width: 10rem;
          max-height: 5rem;
          min-width: 7rem;
          min-height: 1.875rem;
          border-radius: 0.75rem;
          background-color: white;
          padding: 1px;
        }
      }
      > div:last-child {
        display: flex;
        width: 100%;
        padding: 0 2.5rem;
        .groupName {
          width: 100%;
          > p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4375rem;
            line-height: 1.9375rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            margin: 0;
          }
        }
        .groupAddressAndShare {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          > p {
            height: 1.375rem;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.375rem;
            color: #e0e0e0;
            margin: 0;
          }
          > span {
            margin-right: 0.25rem;
            color: #bdbdbd;
            font-size: 1.75rem;
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    background: #ffffff;
    border-radius: 2.25rem 2.25rem 0px 0px;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    .lineBar {
      height: 0.0625rem;
      margin: 1rem 25px 0 25px;
      background: #e8eaf4;
      border-radius: 2.5625rem;
      > span {
        width: 100%;
        background: #e8eaf4;
        border-radius: 2.5625rem;
        height: 100%;
      }
    }
    .venuesList {
      padding: 0.5rem 1.25rem 3rem 1.25rem;
      height: calc(100vh - 19rem);
      padding-bottom: 7rem;
      overflow-y: auto;
      overscroll-behavior: none;
      scroll-behavior: smooth;
      a {
        text-decoration: none;
      }
      > div > div:last-child {
        padding-bottom: 2rem;
      }
    }
    .OrderTypeFilterDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f9f9f9;
      border: 1px solid #f9f9f9;
      box-sizing: border-box;
      border-radius: 1.375rem;
      width: 11.0625rem;
      min-height: 2.125rem;
      float: left;
      margin: 1.5625rem 0 0.4rem 1.5rem;
      > svg {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
      > p {
        font-family: Open Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        margin: 0;
      }
    }
    .OrderTypeFilterDivSelected {
      background-color: #fffce6 !important;
      border: 2px solid #ffdd02 !important;

      .FiltersCountDiv {
        width: 1.375rem;
        height: 1.375rem;
        border-radius: 50%;
        border: 2px solid #ffdd02;
        font-family: Open Sans;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-left: 5px;
      }
    }
  }
}

.contentSlideToTop {
  .groupDetails {
    padding-top: 6rem !important;
    -webkit-transition: all 300ms ease-in-out !important;
    -moz-transition: all 300ms ease-in-out !important;
    transition: all 300ms ease-in-out !important;
    .groupLogo {
      padding-bottom: 1.5rem !important;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
    }
  }
  .content {
    transform: translate3d(0, -3rem, 0);
    .venuesList {
      padding-bottom: 3rem;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100vh - 20.6875rem);
      margin-bottom: 2rem;
    }
    .shadowTop {
      height: 6.25rem;
      background-color: #fff;
      position: absolute;
      top: 4.825rem;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      transform: matrix(1, 0, 0, -1, 0, 0);
      border-radius: 1.25rem;
      z-index: 1;
    }
    .shadowBottom {
      height: 6.25rem;
      background-color: #fff;
      position: absolute;
      bottom: -3rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      border-radius: 1.25rem;
      width: 100%;
      z-index: 1;
    }
  }
}

#DifficultTimesDrawer {
  :global(.MuiDrawer-paperAnchorBottom) {
    /* top: 60px !important; */
    margin: auto;
    max-width: 95vh;
    background-color: transparent !important;
  }
}
.GroupDescription {
  padding: 0rem 2.5rem;
  width: 100%;
  color: blue;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375rem;
  color: #e0e0e0;
  white-space: pre-wrap;
  .ShowMoreText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    color: #9e9e9e;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($color, 0.3), 0 0 0 0.1em rgba($color, 0.3),
      0 0 0 0.3em rgba($color, 0.3), 0 0 0 0.5em rgba($color, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.1em rgba($color, 0.3), 0 0 0 0.3em rgba($color, 0.3),
      0 0 0 0.5em rgba($color, 0.3), 0 0 0 0.8em rgba($color, 0);
  }
}
