.PaymentContainer {
  width: 100%;
  // height: 100%;
  padding: 1.5625rem;
  .Header {
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 1.0625rem;
      line-height: 1.4375rem;
      display: flex;
      align-items: center;
      color: #212121;
      > strong {
        padding-left: 0.3rem;
      }
    }
  }
  .TotalCost {
    display: flex;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    flex-direction: column;
    align-items: center;
    .TotalPrice {
      position: relative;
      color: #9e9e9e;
      font-size: 14px;
      line-height: 19px;
    }
    .Price {
      color: #212121;
      font-size: 28px;
      line-height: 12px;
    }
  }
  .PayWithCard {
    padding-top: 1rem;
    position: relative;
    margin-bottom: 3rem;
    // display: flex;
    // justify-content: space-between;
    .Line {
      margin-top: 0.75rem;
      background: #e8eaf4;
      width: 100%;
      height: 2px;
    }
    > div:last-child {
      position: absolute;
      width: 100%;
      left: 0;
      top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      > p {
        color: #737fa4;
        font-size: 14px;
        width: 100px;
        height: 22px;
        background-color: #fff;
        text-align: -webkit-center;
        text-align: center;
      }
    }
  }
}
// @mixin
// /* £130.50 */

// position: absolute;
// width: 104px;
// height: 38px;
// left: 155px;
// top: 485px;

// font-family: Open Sans;
// font-style: normal;
// font-weight: normal;
// font-size: 28px;
// line-height: 38px;
// display: flex;
// align-items: center;
// text-align: center;

// color: #212121;
// /* Total Price */
// position: absolute;
// width: 72px;
// height: 19px;
// left: 171px;
// top: 461px;

// font-family: Open Sans;
// font-style: normal;
// font-weight: 600;
// font-size: 14px;
// line-height: 19px;
// /* identical to box height */
// display: flex;
// align-items: center;

// color: #9E9E9E;
