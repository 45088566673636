@mixin border-bottom() {
  border-bottom: 1px solid #e8eaf4;
}

@mixin display-flex-Sbetween() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin texts-css($collor, $fontSize, $fontWeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $collor;
  margin: 0;
}

.delivery_time {
  height: auto;
  display: flex;
  flex-direction: column;
  // padding: 0 1.5625rem 1.25rem 1.5625rem;
  background-color: #fff;

  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 1rem;
    > span {
      width: 2.625rem;
      background-color: #e8eaf4;
      height: 0.3125rem;
      position: absolute;
      top: 0.625rem;
    }
  }

  .DeliveryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8rem;
    // padding-bottom: 0.4rem;
    padding: 0 1.5625rem 0.4rem 1.5625rem;
    > p {
      @include texts-css(#737fa4, 1.05rem, 600);
      text-align: center;
      flex: 8;
    }
    // @include border-bottom();
  }
  .divider {
    border-bottom: 1px solid #e8eaf4;
    width: 86%;
    margin: auto;
  }
  .CollectionTimeText {
    @include texts-css(#212121, 1.0625rem, normal);
    padding: 1rem 2.5rem;
    margin-top: 0.5rem;
    > span {
      font-weight: 600;
    }
  }
  > ul {
    padding: 0;
    margin: 0 0 2.2rem 0;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4951rem;
    line-height: 1.75rem;
    /* identical to box height */
    color: #000000;
    list-style: none;
    text-align: center;
    height: 21.875rem;
    overflow-y: auto;
    padding: 0 1.5625rem 0 1.5625rem;
    > li {
      margin: 0.25rem;
      padding: 0.25rem;
    }

    > li:hover {
      background: rgba(0, 82, 100, 0.06);
    }

    .SelectedTime {
      background: rgba(0, 82, 100, 0.06) !important;
    }
  }
  > button {
    background: #e8f3fb;
    box-shadow: 19px 21px 50px rgba(176, 195, 210, 0.727846),
      -8px 0px 8px rgba(244, 248, 251, 0.50254),
      -8px -40px 22px rgba(246, 251, 255, 0.384288),
      -11px -11px 20px rgba(255, 255, 255, 0.272044);
    border-radius: 1.25rem;
    margin: 1.125rem auto 0.75rem auto;
    width: 90%;
    padding: 0.75rem;
    border: 1px solid #e8f3fb;
  }

  .confirmButton {
    padding: 0 1.5625rem 1.25rem 1.5625rem;
  }
}
