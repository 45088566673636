@mixin texts-css($collor, $fontSize, $fontWeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $collor;
  margin: 0;
}

.ConfirmPayment {
  width: 100%;
  height: 100%;
  padding: 0.9375rem 1.875rem;
  background-color: #fff;
  border-radius: 2.25rem 2.25rem 0 0 !important;
  .ThankYouText {
    @include texts-css(#9e9e9e, 1rem, 600);
  }

  .lineBar {
    width: 2.625rem;
    height: 0.3125rem;
    background: #e8eaf4;
    border-radius: 2.5625rem;
    margin: auto;
  }
  .ConfirmOrderContainer {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    margin-top: 3rem;
    padding: 0 0.3rem 5rem 0.3rem;

    .OrderCompletedText {
      @include texts-css(#212121, 1.1rem, normal);
    }

    .OrderCompletedText {
      @include texts-css(#212121, 1.1rem, normal);
    }

    .OrderSentText {
      @include texts-css(#212121, 1rem, normal);
    }
    .OrderSentInfoText {
      @include texts-css(#212121, 1rem, 600);
      margin-bottom: 1rem;
    }

    .OrderInfoBox {
      background: rgba(255, 221, 2, 0.1);
      border: 1px solid #ffdd02;
      box-sizing: border-box;
      border-radius: 0.75rem;
      height: auto;
      padding: 0 1.8rem;
      margin: 0 0.4rem;

      .OrderSentText {
        @include texts-css(#696666, 1rem, normal);
      }

      .OrderIdDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.9rem 0 1.2rem 0;
        > p {
          @include texts-css(#212121, 1.1rem, normal);
        }
      }

      .NotifyText {
        @include texts-css(#212121, 1rem, normal);
      }
    }
  }
}
.infoBox {
  padding: 1.5rem 0;
}

.ReviewSectionDiv {
  background: #f1f0fb;
  border-radius: 14px;
  padding: 15px 25px;
  margin-top: 0.937rem;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > p {
      color: #4f3cc9;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0;
      margin-left: 0.6rem;
    }
  }
}
.ReviewButton {
  border: 1px solid #4f3cc9;
  box-sizing: border-box;
  border-radius: 10px;
  background: transparent;
  padding: 1rem 0;
  text-align: center;
  margin-top: 1rem;
  > p {
    color: #4f3cc9;
    font-size: 15px;
    margin: 0;
  }
}
