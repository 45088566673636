.VenueOrderTypeHeaderTitleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > p {
      font-family: Open Sans;
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 600;
      margin: 0 0 0 1rem !important;
      color: #212121;
    }
    > svg {
      max-height: 22px;
      color: unset !important;
      margin-bottom: 3px;
      width: 24px;
    }
  }
  > span {
    font-size: 1.5rem;
    color: #212121;
  }
}
