.SelectedOrderTypeTitleDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 1rem 1rem;
  > p {
    font-family: Open Sans;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 600;
    margin: 0 0 0 0.625rem;
  }
  > svg {
    max-height: 22px;
  }
}

.OrderTypeTitleDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 1rem 1rem;
  > p {
    font-family: Open Sans;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 0 0.625rem;
  }
  > svg {
    max-height: 22px;
    color: unset !important;
  }
}
