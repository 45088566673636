@mixin texts-css($collor, $fontSize, $fontWeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $collor;
  margin: 0;
}
.DrawerHeader {
  height: auto;
  display: flex;
  flex-direction: column;
  // padding: 0 1.5625rem 1.25rem 1.5625rem;
  background-color: #fff;
  border-top-right-radius: 2.25rem;
  border-top-left-radius: 2.25rem;
  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 1rem;
    > span {
      width: 2.625rem;
      background-color: #e8eaf4;
      height: 0.3125rem;
      position: absolute;
      top: 0.625rem;
    }
  }

  .DeliveryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    padding: 0 1.5625rem 0.4rem 1.5625rem;
    > p {
      @include texts-css(#737fa4, 1.05rem, 600);
      text-align: center;
      flex: 8;
    }
    // @include border-bottom();
  }
  .divider {
    border-bottom: 1px solid #e8eaf4;
    width: 90%;
    margin: auto;
  }
}
