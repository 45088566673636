.sq-apple-pay-button {
  display: none;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  min-width: 200px;
  min-height: 32px;
  max-height: 64px;
  padding: 22px;
  outline: none;
  border: none;
}
.sq-apple-pay-button-black {
  background-image: url("../../../../../imges/Apple_Pay-White-Logo.wine.svg");
  background-color: black;
  background-size: contain;
  background-position: center;
}
