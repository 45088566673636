.selectLanguageContainer {
  height: auto;
  border-radius: 36px 36px 0 0;
  .lineBar {
    width: 100%;
    height: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.9375rem;
    margin-bottom: 1.25rem;
    > span {
      width: 42px;
      height: 100%;
      background-color: #e8eaf4;
      border-radius: 41px;
    }
  }
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
    > div:first-child {
      display: flex;
      align-items: center;
      > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1.0625rem;
        line-height: 1.4375rem;
        color: #212121;
        margin: 0;
      }
      > svg {
        width: 1.1875rem !important;
        height: 1.125rem !important;
      }
    }
  }
  > ul {
    padding: 0;
    margin-top: 1.875rem;
    > li {
      list-style: none;
      margin: 0 1.5625rem;
      margin-bottom: 0.625rem;
      border: 1.5px solid #eeeeee;
      border-radius: 10px;
      padding: 0.875rem 1.875rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        width: 90%;
        > svg {
          width: 1.625rem;
          height: 1.625rem;
          min-width: 1.625rem;
          min-height: 1.625rem;
          max-width: 1.625rem;
          max-height: 1.625rem;
        }
        > span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 1.063rem;
          line-height: 1.438rem;
          color: #212121;
          padding-left: 1.313rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      > span {
        color: #212121;
        font-family: Open Sans;
        font-style: normal;
        font-size: 1.5rem;
      }
    }
  }
}
