.DrawerHeader {
  position: relative;
  font-size: 1.0625rem;
  margin-top: 0.8rem;
  padding: 0.6rem 1.2rem 0;
  box-sizing: border-box;

  > p {
    margin-top: 1rem;
    text-align: left;
    flex: 8;
  }
  .Title {
    margin-top: 0.75rem;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 1.1875rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #e8eaf4;
  }
}

.LineBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  height: 1rem;
  > span {
    border-radius: 0.375rem;
    transform: translate(-50%);
    width: 2.625rem;
    background-color: #e8eaf4;
    height: 0.3125rem;
    position: absolute;
  }
}

.Bold {
  font-weight: 700;
}

.ExitButton {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2rem;
  position: absolute;
  z-index: 2;
  right: 0.5rem;
  top: 0.875rem;
}
