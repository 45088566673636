body {
  margin: auto !important;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Avenir", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Pacifico", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white !important;
  touch-action: manipulation;
  min-height: 100%;
  padding: 0;
  overflow: hidden;
  max-width: 768px;
}

html {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Roboto-Regular.ttf");
  src: url("./fonts/Roboto-Regular.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/Roboto-Regular.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/Roboto-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/avenir_ff/AvenirLTStd-Roman.otf");
  src: url("./fonts/avenir_ff/AvenirLTStd-Roman.otf")
      format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/avenir_ff/AvenirLTStd-Roman.otf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("./fonts/avenir_ff/AvenirLTStd-Roman.otf") format("truetype");
}

@font-face {
  font-family: Pacifico;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Pacifico.woff");
  src: url("./fonts/Pacifico.woff") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/Pacifico.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/Pacifico.woff")
      format("truetype");
}

@font-face {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/SFProDisplay-Regular.ttf");
  src: url("./fonts/SFProDisplay-Regular.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/SFProDisplay-Regular.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/SFProDisplay-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: icon-percentage;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/icomoon.eot");
  src: url("./fonts/icomoon.svg") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/icomoon.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/icomoon.ttf")
      format("truetype");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/OpenSans-Regular.ttf");
  src: url("./fonts/OpenSans-Regular.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/OpenSans-Regular.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/OpenSans-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: Open Sans;
  font-style: bold;
  font-weight: bold;
  src: url("./fonts/OpenSans-Bold.ttf");
  src: url("./fonts/OpenSans-Bold.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/OpenSans-Bold.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/OpenSans-Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Lato-Bold.ttf");
  src: url("./fonts/Lato-Bold.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/Lato-Bold.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/Lato-Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Lato-Regular.ttf");
  src: url("./fonts/Lato-Regular.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/Lato-Regular.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/Lato-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Muli-Bold.ttf");
  src: url("./fonts/Muli-Bold.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/Muli-Bold.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/Muli-Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Muli-Regular.ttf");
  src: url("./fonts/Muli-Regular.ttf") format("embedded-opentype"),
    /* IE6-8 */ url("./fonts/Muli-Regular.ttf") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("./fonts/Muli-Regular.ttf")
      format("truetype");
}
