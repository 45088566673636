.shareBoxContainer {
  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.1875rem;
    > span {
      width: 2.625rem;
      height: 0.3125rem;
      background: #e8eaf4;
      border-radius: 2.5625rem;
    }
  }
  .ShareWrapper {
    > div {
      width: 100%;
      background: unset !important;
      padding: 0 2.5rem !important;
      > div:first-child {
        display: none !important;
      }
      > p {
        margin-bottom: 2.1875rem !important;
        margin-right: 0 !important;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 1.0625rem;
        line-height: 1.4375rem;
        display: flex;
        align-items: center;
        color: #212121;
        justify-content: center;
      }

      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > div {
          margin: unset !important;
          top: -4.3rem;
          left: -1rem;
          font-size: 1.5rem;
          .ShareItemDIv {
            width: 2.875rem !important;
            height: 2.875rem !important;
          }
        }
      }
    }
  }
}
