@mixin texts-css($collor, $fontSize, $fontWeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $collor;
  margin: 0;
}

.PostCodeChecker {
  .PostCodeText {
    @include texts-css(#212121, 1rem, 400);
    margin: 1.475rem 0.9375rem 1.1625rem 0.9375rem;
  }

  .PostCodeCheckerContent {
    padding: 0 1.5625rem 1.5625rem 1.5625rem;
  }

  .PostCodeInput {
    width: 100%;
    background: #f9f9f9;
    border-radius: 10px;
    border: none;
    height: 3.125rem;
    box-sizing: border-box;
    border-radius: 0;
    text-align: left;
    padding-left: 1.2rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #212121;
    width: 100%;
    margin-bottom: 0.8rem;
  }
  .Description {
    margin: 1.875rem 0.9375rem 1rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #212121;
  }
}
