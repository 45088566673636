.MenuContainer {
  display: flex;
  max-width: 768px;
  flex-direction: column;
  overflow: hidden;
}

.FixedCheckout {
  display: flex;
  max-width: 768px;
  position: absolute;
  bottom: 1rem;
  justify-content: flex-end;
  padding-right: 2rem;
  @media only screen and (min-width: 768px) {
    width: 768px;
  }
  @media only screen and (max-width: 767px) {
    padding-right: 0;
    right: 2rem;
  }
}
.checkoutBtn {
  background: #ffdd02;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  padding: 0.875rem 1.75rem 0.875rem 1.25rem;
  max-height: 3rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  // margin: 1.5rem 2.5rem 1.5rem 0;
  width: 10.8125rem;
  flex-direction: row-reverse;
  justify-content: space-around;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #212121;
    margin: 0;
    height: 1.25rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
  }
  > span {
    color: #212121;
    font-size: 1.75rem;
  }
}

.TopTabs {
  display: flex;
  border-bottom: 1px solid #e8eaf4;
}

.MenuContents {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.isScrolled {
  transition: transform 750ms;
  transform: translateY(-6.5rem);
}
.isScrolledOut {
  transition: transform 500ms;
  transform: translateY(0);
}

.isScrollNavigate {
  // transition: transform 4s .25s cubic-bezier(0,1,.3,1);
  // will-change: transform;
  // display: none;
  // visibility: hidden;
  // opacity: 0;
  transition: all 0.5s linear;
  transform-origin: top;
  // transition: visibility 0s 1s, opacity 1s linear;
  transform: scaleY(0);
}

.isVisibleNavigate {
  display: flex;
  transition: all 0.25s linear;
  transform-origin: top;
  transform: scaleY(1);
}

.Navigationheader {
  padding: 0;
}
