html {
  font-size: 16px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  /* overflow: hidden; */
  /* position: relative; */
}
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
@media (max-width: 375px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 321px) {
  html {
    font-size: 12px;
  }
}

.Flex {
  display: flex;
}

.AlignCenter {
  align-items: center;
}

.JustBetween {
  justify-content: space-between;
}
.JustCenter {
  justify-content: center;
}

.FlexFlow {
  flex-flow: row wrap;
}

.FlexFlowWrap {
  flex-flow: wrap;
}
/* 
.PrivateTabIndicator-colorPrimary-65{
    background-color: unset !important
}

.jss65{
    background-color: unset !important
    
}
.jss82{
    background-color: unset !important
    
} */

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.onHover:hover {
  cursor: pointer;
}

.MuiTabs-flexContainer span {
  background-color: none !important;
}

.Flex1 {
  flex: 1;
}
.Flex2 {
  flex: 2;
}
.Flex3 {
  flex: 3;
}
.Flex4 {
  flex: 4;
}
.Flex5 {
  flex: 5;
}
.Flex6 {
  flex: 6;
}
.Flex11 {
  flex: 11;
}
.MuiSnackbar-root {
  z-index: 9999999999 !important;
}

.Grid {
  padding-bottom: 50px;
}
.LottieLoading {
  margin: 0px;
  /* height: 100%; */
  overflow: hidden;
  width: 20%;
  /* width: 100%; */
}

#SIDEMENUDRAWER .MuiDrawer-paper {
  background: #fff !important;

  /* border-top-right-radius: 24px; */
  /* border-bottom-right-radius: 24px;  */
  right: 0;
}
.MuiAlert-standardSuccess .MuiAlert-icon {
  margin-top: auto;
  margin-bottom: auto;
}
.MuiAlert-standardError .MuiAlert-icon {
  margin-top: auto;
  margin-bottom: auto;
}
#PostCodeChecker .MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 2.25rem;
  border-top-right-radius: 2.25rem;
}
#SWIPEDRAWER .MuiDrawer-paperAnchorBottom {
  background-color: #e5e5e5 !important;
}
#SWIPEDRAWER .MuiDrawer-paper {
  overflow-y: unset !important;
}

#SHAREDRAWER .MuiDrawer-paper {
  overflow-y: unset !important;
  border-radius: 2.25rem 2.25rem 0 0 !important;
}
#SupportDrawer .MuiDrawer-paper {
  border-radius: 2.25rem 2.25rem 0 0 !important;
}
#SelectLanguageDrawer .MuiDrawer-paper {
  border-radius: 2.25rem 2.25rem 0 0 !important;
}
#DifferentTimezoneWarning .MuiDrawer-paper {
  border-radius: 2.25rem 2.25rem 0 0 !important;
}

#ProductDetailsDrawer .MuiDrawer-paperAnchorBottom {
  top: 0;
  overflow: hidden;
}

#GroupPageFilterDrawer .MuiDrawer-paperAnchorBottom {
  top: 0;
}

.rotate-device {
  display: none;
}

.ant-carousel .slick-dots {
  height: fit-content !important;
  max-height: 5rem;
}

.ant-carousel .slick-dots li button {
  background: rgba(255, 255, 255, 0.5);
}
.react-tel-input .selected-flag {
  position: relative;
  width: 2.375rem !important;
  height: 100%;
  padding: unset !important;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (orientation: landscape) and (max-width: 1200px) {
  .rotate-device {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 99999;
  }
}
#deliveryTimeDrower .MuiDrawer-paperAnchorBottom {
  background: #fff;
  box-shadow: 0px 0px 59.7696px rgba(102, 102, 102, 0.727846);
  border-radius: 21.7344px 21.7344px 0 0;
}
#DifferentTimezoneWarning .MuiDrawer-paperAnchorBottom {
  background: #fff;
  box-shadow: 0px 0px 59.7696px rgba(102, 102, 102, 0.727846);
  border-radius: 21.7344px 21.7344px 0 0;
}

#PaymentsDrawer .MuiDrawer-paperAnchorBottom {
  background: #fff;
  box-shadow: 25px 28px 66px rgba(176, 195, 210, 0.727846);
  border-radius: 24px 24px 0px 0px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffdd02 !important;
  border-color: #ffdd02 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ffdd02 !important;
}
.ant-checkbox-checked::after {
  border-color: #ffdd02 !important;
}

.CartItem-exit {
  transform: translateX(0%);
}
.CartItem-exit-active {
  max-height: 0 !important;
  overflow: hidden;
  transition: all 700ms ease-in-out;
}
