.Ring {
  position: absolute;
  height: 12rem;
  width: 12rem;
  border-radius: 50%;
  // margin: 50px;
  border: 2px solid white;
  z-index: -999;
  top: 50%;
  left: 50%;
  margin: -6rem 0 0 -6rem;
  // top: 11.5rem;
}
.Ring:before,
.Ring:after,
.Ring > span {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  border-radius: 50%;
  box-shadow: 0 0 0.25rem #ffdd02;
  animation: glow-grow 3s ease-out infinite;
}
.Ring:after {
  animation-delay: 0.66s;
}
.Ring > span {
  animation-delay: 1.33s;
}
@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
