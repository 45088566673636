.shoppingCartContainer {
  flex: 0 0 5.625rem;
  position: relative;
  .shoppingCartBtn {
    height: 100%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #2a2a2f;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;

    .countLabel {
      position: absolute;
      width: 1.875rem;
      height: 1.875rem;
      right: 12.625rem;
      background: #fb3449;
      border-radius: 0.5rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5625rem;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .myCart {
    width: 100%;
    height: 100%;
    .shopListContainer {
      width: 100%;
      padding: 0 0.4375rem;
      outline: none !important;
      max-width: 768px;
      position: relative;
      padding: 0 1rem;
      height: 100%;
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        .lineBar {
          width: 2.625rem;
          height: 0.3125rem;
          background: #e8eaf4;
          border-radius: 2.5625rem;
          margin: auto;
          margin-top: 1rem;
          margin-bottom: 0.875rem;
        }
      }

      .cartDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 1.5rem;
        > div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 1rem;
          > div:first-child {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            > p {
              margin: 0;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.6875rem;
              display: flex;
              align-items: center;
              color: #212121;
            }
            > svg {
              margin-right: 1rem;
              height: 1.25rem;
              width: 1.25rem;
            }
          }
          .closeDrawer {
            border-radius: 50%;
            color: #aab6d3;
            font-size: 1.5rem;
            width: 2rem;
            height: 2rem;
            background: unset;
            position: inherit;
          }
        }
        > div:last-child {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 0.625rem;
          > div:first-child {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            > p {
              margin: 0;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 1.25rem;
              display: flex;
              align-items: center;
              color: #212121;
            }
            .dot {
              background: #aab6d3;
              height: 0.375rem;
              width: 0.375rem;
              border-radius: 50%;
              margin-right: 0.5rem !important;
            }
            .cartItemsCount {
              font-size: 1rem;
              line-height: 1.375rem;
              color: #8d98b7;
            }
            > svg {
              margin-right: 1rem;
            }
          }
          > div:last-child {
            .emptyCart {
              background: #f9f9f9;
              border-radius: 3.5rem;
              padding: 0.5rem 0.9375rem;
              > p {
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 0.8125rem;
                display: flex;
                align-items: center;
                text-align: center;
                color: #212121;
                margin: 0;
              }
            }
          }
        }
      }
      .cartItems {
        height: calc(100% - 13rem);
        overflow-y: auto;
      }
    }
    .contentSlideToTop {
      .shadowTop {
        height: 3.25rem;
        background-color: #fff;
        position: absolute;
        width: 100%;
        z-index: 1;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
        transform: matrix(1, 0, 0, -1, 0, 0);
        border-radius: 1.25rem;
        left: 0;
        top: 7.8rem;
      }
    }
    .contentSlideToBottom {
      .shadowBottom {
        height: 3.25rem;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
        border-radius: 1.25rem;
        width: 100%;
        left: 0;
      }
    }
  }
  .shoppingCartDrawer {
    max-width: 768px;
    height: calc(100vh - 8.5rem);
    bottom: 0;
    position: fixed;
    width: 100%;
    border-top-right-radius: 2.25rem;
    border-top-left-radius: 2.25rem;
    background-color: #fff;
    z-index: 2;
    transform: translate3d(0, 100%, 0);
    -webkit-transition: transform 300ms ease-in-out;
    -moz-transition: transform 300ms ease-in-out;
    -ms-transition: transform 300ms ease-in-out;
    -o-transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
  }

  .openCart {
    transform: translate3d(0, 0, 0);
  }
  .drawerMask {
    height: 100vh !important;
    display: none;
    width: 100%;
    max-width: 768px;
    bottom: 0;
    position: fixed;
    // opacity: 0;
    // transition: all 250ms ease-in;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .maskBackground {
    display: block;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    z-index: 1;
    // transition: all 250ms ease-out;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
}
.deletedItem {
  max-height: 0 !important;
  transition: max-height 900ms ease-in-out;
  overflow: hidden;
  > div {
    background: #ffbebebd !important;
  }
}
.deletedFromCart {
  max-height: 0 !important;
  transition: max-height 250ms ease-in-out;
  overflow: hidden;
  > div {
    background: #ffbebebd !important;
  }
}

.repeatBtn {
  background: #ffdd02;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  padding: 0.875rem 1.75rem 0.875rem 1.25rem;
  max-height: 3rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  width: 16.5rem;
  flex-direction: row-reverse;
  > p {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #212121;
    margin: 0;
    height: 1.25rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    width: 100%;
  }
  > span {
    color: #212121;
    font-size: 1.75rem;
  }
}
.emptyCheckoutBtn {
  padding: 0.875rem 1.75rem 0.875rem 1.25rem;
  max-height: 3rem;
  background-color: white;
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.8125rem;
  flex-direction: row-reverse;
  > p {
    margin: 0 !important;
    height: 1.25rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #757575;
  }
  > span {
    color: #757575;
    font-size: 1.75rem;
    margin-right: 0.5625rem;
  }
}
.checkoutBtn {
  background: #ffdd02;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  padding: 0.875rem 1.75rem 0.875rem 1.25rem;
  max-height: 3rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  width: 10.8125rem;
  flex-direction: row-reverse;
  justify-content: space-around;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #212121;
    margin: 0;
    height: 1.25rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
  }
  > span {
    color: #212121;
    font-size: 1.75rem;
  }
}

.ActiveShoppingBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CartCountIcon {
  background-color: #fb3449;
  color: white;
  border-radius: 8px;
  width: 1.8rem;
  text-align: center;
  height: 1.5rem;
  position: relative;
  left: 1rem;
  z-index: 1;
  font-weight: 600;
  font-size: 1rem;
}

.ShoppingCartBtnContainer {
  display: flex;
  // flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.subTotalPrice {
  display: flex;
  flex-direction: column;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    color: #9e9e9e;
    margin: 0;
  }
  > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: black;
  }
}
.emptyCartText {
  padding: 2.3125rem 1.5625rem 2.3125rem 2.5rem;
  > p {
    height: 1.375rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    color: #9e9e9e;
    margin: 0;
  }
}

.confirmOrderBtn {
  background: #ffdd02;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0.875rem 1rem;
  max-height: 3rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  flex-direction: row;
  justify-content: space-around;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #212121;
    margin: 0;
    height: 1.25rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
  }
  > span {
    color: #212121;
    font-size: 1.75rem;
  }
}
