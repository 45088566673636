.DrawerOpen {
  height: calc(100vh - 17.25rem);
  width: 100%;
  left: 0;
  bottom: 0;
  position: fixed;
  border-top-right-radius: 2.25rem;
  border-top-left-radius: 2.25rem;
  background-color: #fff;
  z-index: 9999;
  transform: translate3d(0, 100%, 0);
  -webkit-transition: transform 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out;
  -ms-transition: transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}
.openCart {
  transform: translate3d(0, 0, 0);
}
.drawerMask {
  height: 100vh !important;
  display: none;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  // opacity: 0;
  // transition: all 250ms ease-in;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.maskBackground {
  display: block;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  z-index: 1000;
  // transition: all 250ms ease-out;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
