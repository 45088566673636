.RefundedReason {
  display: flex;
  align-items: center;
  padding-left: 0.9375rem;
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */
    color: #fa7800;
    margin: 0;
  }
  p:last-child {
    font-weight: 300;
  }
  > svg {
    margin-right: 0.625rem;
  }
}
