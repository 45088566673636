.Container {
  padding: 0.75rem 0;
  margin: 0;
  overflow-y: auto;
  height: calc(100vh - 3.6875rem);
  padding-bottom: 12.5rem;
  background-color: #f9f9f9;
  margin-top: 5rem;
  padding-top: 1.2rem;
  .OrderReference {
    padding: 0.75rem 2rem;
    background-color: #ffffff;
    .VenueDetails {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      flex-direction: row;
      border-bottom: #e8eaf4 1px solid;
      > img {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 0.5rem;
      }
      > div {
        display: flex;
        flex-direction: column;
        > p {
          margin: 0;
          margin-left: 1rem;
          font-size: 1.25rem;
        }
        .AddressLine {
          color: #616161;
          font-size: 0.9rem;
        }
        .taxCodes {
          color: #757575;
          font-size: 0.8rem;
        }
      }
    }
    .TopRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .OrderId {
        p {
          font-family: Open Sans;
          font-style: normal;
          margin: 0;
        }
        > p:first-child {
          font-weight: normal;
          font-size: 0.9375rem;
          line-height: 1.25rem;
          display: flex;
          align-items: center;
          color: #8d98b7;
          padding-bottom: 0.3125rem;
        }
        > p:last-child {
          font-weight: 600;
          font-size: 1.375rem;
          line-height: 1.875rem;
          display: flex;
          align-items: center;
          letter-spacing: 0.0625rem;
          color: #212121;
        }
      }
    }
    .Columns {
      padding-top: 0.25rem;
      display: flex;
      flex-direction: column;
      > div {
        margin-top: 0.75rem;
      }
      .OrderTypes {
        display: flex;
        justify-content: space-between;
        > div:first-child {
          display: flex;
          > svg {
            width: 1.3125rem;
            height: 1.25rem;
          }
          > p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.625rem;
            display: flex;
            align-items: center;
            color: #212121;
            margin: 0;
            padding-left: 0.625rem;
          }
        }
      }
    }
  }
  .PaymentDetails {
    padding: 0.75rem 2rem 0.5rem;
    background-color: #ffffff;
    margin-top: 0.8rem;
    .PaymentRow:last-child {
      font-weight: 600;
      border-top: 1px solid #e8eaf4;
      padding-top: 1.5rem;
    }
  }
  .ItemSummary {
    padding: 0.75rem 2rem 0;
    background-color: #ffffff;
    margin-top: 0.8rem;
    > p {
      color: #fa7800;
      font-family: Open Sans;
      font-style: normal;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      color: #fa7800;
    }
    :global(.MuiTabs-root) {
      margin-bottom: 1.25rem;
    }
    :global(.MuiTabs-flexContainer) {
      border-bottom: 1px solid #e0e0e0;
      > button {
        width: 50% !important;
      }
    }
    :global(.MuiTabs-scroller) {
      > span {
        background-color: #ffdd02 !important;
      }
    }
    .ItemEntity:first-child {
      margin-top: 0;
    }
    .ItemEntity:last-child {
      border-bottom: 0;
    }
  }
}

.PaymentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 2.5rem;
  > p:first-child {
    font-family: Open Sans;
    font-size: 1rem;
    line-height: 1.45rem;
    color: #616161;
  }
  > p:last-child {
    color: #212121;
  }
}
.PartialRefundDetailsRow {
  > div {
    p {
      color: #fa7800 !important;
    }
  }
  > div:last-child {
    border-top: unset !important;
    font-weight: unset !important;
    padding-bottom: 1.25rem !important;
  }
  border-bottom: 1px solid #e8eaf4 !important;
  margin: 1.25rem 0;
}
.PaymentDiscount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  max-height: 2.5rem;
  > p {
    color: #fb3449;
  }
  > div:first-child {
    font-family: Open Sans;
    font-size: 1rem;
    line-height: 1.45rem;
    color: #616161;
    display: flex;
    flex-direction: column;
    max-width: 50%;
    margin: 0;
    > div:last-child {
      font-size: 0.75rem;
    }
  }
}
.PaymentMethods {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
  > svg {
    margin-right: 0.3125rem;
  }
}
.ItemEntity {
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e8eaf4;
  margin-top: 1rem;
  > ul {
    padding-left: 1.5rem;
    margin-top: 0.75rem;
  }
  .Title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.45rem;
    display: flex;
    align-items: center;
    color: #212121;
    margin: 0;
    margin-bottom: 0.4375rem;
  }
  .Pricing,
  .Extras {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    > div:first-child {
      > p:first-child {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9375rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: #424242;
        width: 75%;
        margin-bottom: 0.125rem;
      }
      > p:last-child {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1.0625rem;
        line-height: 1.4375rem;
        display: flex;
        align-items: center;
        text-align: right;
        color: #212121;
        margin-bottom: 0.125rem;
      }
    }
    .DiscountDetails {
      > p:first-child {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        color: #fb3449;
        margin: 0;
        margin-bottom: 1rem;
      }
      > p:last-child {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8125rem;
        line-height: 1.125rem;
        display: flex;
        align-items: center;
        text-align: right;
        text-decoration-line: line-through;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
.SectionHeader {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.1rem;

  display: flex;
  align-items: center;
  width: 100%;
  color: #212121;
  margin-bottom: 0.75rem;
}
.OrderTitle {
  display: flex;
  flex-direction: column;
  p {
    font-family: Open Sans;
    font-style: normal;
    margin: 0;
  }
  > p:first-child {
    margin-top: 0.5rem;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: #8d98b7;
    padding-bottom: 0.3125rem;
  }
  > p:last-child {
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.0625rem;
    color: #212121;
  }
}

.Large {
  font-weight: 600;
  font-size: 1.375rem;
}
.Small {
  font-weight: normal;
  font-size: 1.125rem;
}
.RefundedTitle {
  display: flex;
  align-items: center;
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */
    color: #fa7800;
    margin: 0;
  }
  > svg {
    margin-right: 0.625rem;
  }
}
.RefundedItem {
  background: #feeee0;
  border: 1px solid #fa7800;
  box-sizing: border-box;
  border-radius: 0.875rem;
  padding: 0.9375rem;
  margin: 0.625rem 0;
  // > div:first-child {
  //   display: flex;
  //   align-items: center;
  //   p {
  //     font-family: Open Sans;
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 0.875rem;
  //     line-height: 1.1875rem;
  //     /* identical to box height */
  //     color: #fa7800;
  //     margin: 0;
  //   }
  //   > svg {
  //     margin-right: 0.625rem;
  //   }
  // }
}

.OrderReviewButtonDiv {
  background: #4f3cc9;
  border-radius: 10px;
  padding: 1rem 0;
  text-align: center;
  margin-top: 0.5rem;
  > p {
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 0;
  }
}
