.ConfirmPayments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .CardDetailsWrapper {
    display: contents;
    width: 100%;
  }
  .CardDetails {
    display: flex;
    justify-content: space-between;
    .Lines {
      padding: 0.4rem 1vw;
      .Dots {
        padding: 2px;
        margin: 1px;
        background-color: #9e9e9e;
        border-radius: 50%;
        display: inline-block;
        width: 3px;
        height: 3px;
      }
    }
  }
  .CVVWrapper {
    width: 100%;
    margin-top: 1.5rem;
  }
  .InputWrapper {
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
    }
  }
  // .IconWrapper {
  //   min-width: 2rem;
  // }
  .changeCardButton {
    margin-top: 0.3rem;
    background-color: #f9f9f9;
    border: 1px #f9f9f9;
    box-sizing: border-box;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    > p {
      margin: 0 !important;
      height: 1.25rem;
      font-family: Open Sans;
      font-style: normal;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: #212121;
    }
  }
  .CardNumber {
    // margin-left: 0.45rem;
    display: flex;
    > p {
      padding-left: 0.2rem;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.6875rem;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      color: #212121;
    }
  }
  .CVVLabel {
    margin-left: 0.5rem;
    margin-bottom: 0;
    display: block;
    font-size: 0.75rem;
  }
  .CVVInput {
    margin-left: 0.5rem;
    border: none;
    outline: none;
    background-color: #f9f9f9;
    border: 1px solid #f9f9f9;
    padding: 0.7rem;
    border-radius: 6px;
    width: 6rem;
    transition: 0.25s ease-in-out;
    &:focus {
      border: 1px solid #ffdd02;
      background-color: #ffffff;
    }
  }
  .SubmitCardDetails {
    width: 100%;
    margin-top: 1rem;
    .SubmitCardDetailsButton {
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      height: 4.5rem;
      position: relative;
      > p {
        margin: 0 !important;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
        display: flex;
        align-items: center;
        color: #212121;
        > svg {
          margin-left: 0.75rem;
        }
      }
      > svg {
        position: absolute;
        right: 1rem;
      }
    }
  }
}
