.CardDetails {
  padding: 1.125rem;
  & form {
    padding-bottom: 2.5rem;
  }
  .InputWrapper {
    color: #212121;
    font-size: 1em;
    background-color: #f9f9f9;
    display: block;
    width: 100%;
    margin: 0.75rem 0;
    box-sizing: border-box;
    border-radius: 0.375rem;
    outline: unset;
    border: none;
    padding: 1.125rem;
    & input {
      width: 100%;
      outline: unset;
      border: none;
      background-color: transparent;
      &::placeholder {
        color: #9e9e9e;
        font-size: 1em;
      }
    }
  }
  .checkboxWrapper {
    margin: 1.375rem 0;
    > label {
      display: flex;
      justify-content: flex-start;
      > span:first-child {
        margin-top: 6px;
        outline: unset;
        height: fit-content;
      }
      > span:last-child {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9375rem;
        line-height: 1.625rem;
      }
    }
  }
}
