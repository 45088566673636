@mixin border-bottom() {
  border-bottom: 1px solid #e8eaf4;
}

@mixin display-flex-Sbetween() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin texts-css($color, $fontSize, $fontWeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $color;
  margin: 0;
}
@mixin input-payments {
  background: rgba(255, 221, 2, 0.1);
  border: 2px solid #ffdd02;
  box-sizing: border-box;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.1875rem;
}
.paymentNavigationHeader {
  border-bottom: 1px solid #e8eaf4 !important;
  position: fixed;
}
.Payment {
  width: 100%;
  height: calc(100% - 5.0625rem);
  overflow-y: auto;
  position: relative;
  // padding: 20px 10px;
  overflow-x: hidden;
  max-width: 768px;
  margin-top: 5.0625rem;
  padding-bottom: 5.0625rem;
  .headerConteiner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
    > img {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 0.25rem;
    }
    > p {
      margin-left: 0.25rem;
      color: #212121;
      font-size: 1.1875rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  .BottomBar {
    background: #2a2a2f;
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 768px;
    > div {
      margin: 0 2rem;
    }
  }

  .OrderTypeNotSelectedText {
    @include texts-css(#9e9e9e, 1rem, normal);
  }
}

.ChooseMethod {
  width: 100%;
  // height: 100%;
  padding: 0 1.5rem;
  // margin-bottom: 6.875rem;
  .BillSummaryDiv {
    // @include border-bottom();
    max-height: 0;
    -moz-transition: max-height 0.25s ease-out;
    -ms-transition: max-height 0.25s ease-out;
    -o-transition: max-height 0.25s ease-out;
    -webkit-transition: max-height 0.25s ease-out;
    transition: max-height 0.25s ease-out;
    overflow: hidden;
    // transition: max-height 0.25s ease-out;
    .TotalPriceDiv {
      // background: #f9f9f9;
      padding: 0.9375rem 0.875rem;
      @include display-flex-Sbetween();
      > p {
        @include texts-css(#212121, 1.125rem, 600);
      }
    }
    .Discount {
      padding: 0.625rem 0.875rem;
      @include display-flex-Sbetween();
      > p {
        @include texts-css(#fb3449, 0.9375rem, normal);
      }
    }
    .BillSummarySectionDivs {
      padding: 0.625rem 0.875rem;
      @include display-flex-Sbetween();
      p {
        @include texts-css(#212121, 0.9375rem, normal);
      }
    }
  }
  .BillSummaryDiv.open {
    max-height: 500px;
    -moz-transition: max-height 0.25s ease-in;
    -ms-transition: max-height 0.25s ease-in;
    -o-transition: max-height 0.25s ease-in;
    -webkit-transition: max-height 0.25s ease-in;
    transition: max-height 0.25s ease-in;
    // transition: max-height 0.15s ease-in;
  }

  .rotateSVG {
    svg {
      transform: rotate(-180deg);
      transition: transform 0.25s ease-in;
    }
  }

  .OrderTypeSection {
    padding: 2rem 0 1.5625rem 0;
  }

  .TipsSectionDiv {
    padding-bottom: 1.5625rem;
    .TipStaffText {
      @include texts-css(#212121, 1rem, 600);
      margin-bottom: 0.9375rem;
      padding-left: 0.625rem;
    }
  }

  .ChooseMethodText {
    @include texts-css(#8d98b7, 0.9375rem, normal);
    margin: 0 0 1rem 1rem !important;
    text-align: left;
  }

  .TipCardInputDiv {
    @include input-payments();
    width: 4.5rem;
    height: 2.25rem;
    > span {
      position: absolute;
      left: -2px;
      top: 0;
      bottom: 4px;
      margin: auto;
      width: 1.25rem;
      height: 1.375rem;
      display: flex;
      font-size: 1.2rem;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
  .MessageInputDiv {
    background: #f9f9f9;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.25rem;
    > input {
      background-color: transparent !important;
      text-align: left;
      padding-left: 1.5625rem;
    }
  }
  .TipCardInput {
    width: 100%;
    /* height: 100%; */
    border: none;
    background-color: rgba(255, 221, 2, 0.1);
    padding: 0.125rem 0.25rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    color: #212121;
    margin: 0;
  }
}

.delivery_time {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 1.5625rem 1.25rem 1.5625rem;
  background-color: #fff;

  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 1rem;
    > span {
      width: 2.625rem;
      background-color: #e8eaf4;
      height: 0.3125rem;
      position: absolute;
      top: 0.625rem;
    }
  }

  .DeliveryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8rem;
    padding-bottom: 0.4rem;

    > p {
      @include texts-css(#737fa4, 1.05rem, 600);
      text-align: center;
      flex: 8;
    }
    @include border-bottom();
  }

  > ul {
    padding: 0;
    margin: 0 0 2.2rem 0;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4951rem;
    line-height: 1.75rem;
    /* identical to box height */
    color: #000000;
    list-style: none;
    text-align: center;
    height: 21.875rem;
    overflow-y: auto;

    > li {
      margin: 0.25rem;
      padding: 0.25rem;
    }

    > li:hover {
      background: rgba(0, 82, 100, 0.06);
    }

    .SelectedTime {
      background: rgba(0, 82, 100, 0.06) !important;
    }
  }
  > button {
    background: #e8f3fb;
    box-shadow: 19px 21px 50px rgba(176, 195, 210, 0.727846),
      -8px 0px 8px rgba(244, 248, 251, 0.50254),
      -8px -40px 22px rgba(246, 251, 255, 0.384288),
      -11px -11px 20px rgba(255, 255, 255, 0.272044);
    border-radius: 1.25rem;
    margin: 1.125rem auto 0.75rem auto;
    width: 90%;
    padding: 0.75rem;
    border: 1px solid #e8f3fb;
  }
}
.DeliveryText {
  @include texts-css(#212121, 1rem, normal);
  padding: 1rem;
  margin-top: 0.5rem;
  > .AddressInfo {
    font-weight: 600;
  }
}
// @media only screen and (max-height: 750px) {
//   .ChooseMethod {
//     margin-bottom: 7rem !important;
//   }
// }

.NoOrderType {
  @include texts-css(#000000, 0.85rem, 600);
  padding: 0.45rem;
  text-align: center;
}

.applyDiscount {
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  flex-direction: row-reverse;
  color: #212121;
  align-items: center;
  justify-content: center;
  padding: 0.6875rem 0.6875rem;
  margin-right: 0.3125rem;
  cursor: pointer;
  > p {
    margin: 0;
    margin-left: 0.625rem;
    line-height: 1.25rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375rem;
  }
  > span {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.discountsInputWrapper {
  &:focus-within {
    background: #ffffff !important;
    border: 1px solid #ffdd02 !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(255, 221, 2, 0.2);
    border-radius: 10px;
  }
  > section {
    width: 12.5rem !important;
    right: -3.75rem !important;
    position: absolute;
  }
  .appliedDiscount {
    display: flex;
    font-weight: 600;
    flex-direction: row-reverse;
    font-family: Open Sans;
    font-style: normal;
    font-size: 0.9375rem;
    align-items: center;
    color: #212121;
    align-items: center;
    justify-content: center;
    padding: 0.6875rem 0.9375rem;
    margin-right: 0.3125rem;
    color: #24c277;
    > p {
      margin: 0;
      margin-left: 0.2rem;
    }
    > span {
      font-size: 1.7rem;
      font-weight: 600;
    }
  }
}
.discountsCodeApplyMessage {
  margin-top: 0.3125rem;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .successMessage {
    color: #24c277;
  }
  .errorMessage {
    color: #f65164;
  }
}
.error {
  border: 0.5px solid #f65164 !important;
}
.totalDiscounts {
  align-items: baseline !important;
  > p:first-child {
    > p:last-child {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1rem;

      color: #424242;
    }
  }
  > p:last-child {
    color: #f65164 !important;
  }
}
.TipClearButtonDiv {
  display: flex;
  justify-content: center;
  align-items: baseline;
  > p {
    @include texts-css(#757575, 0.8125rem, normal);
    margin-left: 3px;
    padding-bottom: 2px;
  }
}

.PaymentFooterTotalPrice {
  flex: 1;
  > p:first-child {
    @include texts-css(#9e9e9e, 0.875rem, 600);
    margin: 0;
  }
  > p {
    @include texts-css(#ffffff, 1.5rem, bold);
    margin: 0;
  }
}
.ProceedPaymentText p {
  font-size: 0.9375rem;
}
.TotalPriceWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 0.875rem 1.25rem 0.875rem;
  > p {
    @include texts-css(#212121, 1.1875rem, 600);
    line-height: 1.625rem;
  }
  > div {
    display: flex;
    align-items: center;
    svg {
      transition: 0.25s ease-in;
      width: 0.875rem;
      height: 0.4375rem;
      margin-left: 0.625rem;
    }
    p {
      @include texts-css(#212121, 1.25rem, 600);
      line-height: 1.6875rem;
    }
  }
}
.PricingWrapper {
  border-top: 1px solid #e8eaf4;
  padding: 1.5625rem 0;
  > ul {
    padding: 0;
  }
}
