.AddressForm {
  width: 100%;
  padding: 14px;
  height: 375px;
}

.AddressForm .FormTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 0;
}

.AddressForm .MuiOutlinedInput-root {
  border-radius: 16px;
}

.AddressForm .MuiOutlinedInput-notchedOutline {
  border: 1px solid #8fccff;
}

.AddressForm .MuiFormLabel-root {
  color: #8fccff;
}

.AddressForm .MuiInputBase-input {
  color: #2e476e;
}

.AddressForm .MuiOutlinedInput-input {
  padding-top: 10px;
  padding-bottom: 10px;
}

.SignUpInputDivs {
  width: 100%;
  /* border: 2px solid #FAC000; */
  background: #f9f9f9;
  border-radius: 10px;
  height: 50px;
  position: relative;
}

.SignUpInput {
  background: transparent !important;
  border: none;
  box-sizing: border-box;
  border-radius: 0;
  height: 100%;
  text-align: left;
  padding-left: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #9e9e9e;
  width: 100%;
}
