.PreferenceContainer {
  border: unset;
  background-color: #ffffff;
  :global(.ant-collapse-header) {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 0px;
    display: flex;
    align-items: center;
    color: #212121;
    padding-left: 1.125rem !important;
    > span {
      left: unset !important;
      right: 1.25rem;
      > svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }
  :global(.ant-collapse-content-box) {
    padding: unset;
  }
  :global(.ant-collapse-content) {
    border-top: unset;
  }
}

.PreferenceGroupLabel {
  margin-left: 0.9375rem;
  font-weight: 600;
  font-size: 1.125rem;
  color: #212121;
}
