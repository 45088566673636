.headerBtn {
  position: absolute;
  width: 2.875rem;
  height: 2.875rem;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0.75rem;
  > svg {
    width: 1.25rem;
    height: 1.125rem;
  }
}
.disabled {
  background: transparent !important;
  border: 1px solid #9e9e9e !important;
  pointer-events: none;
  > p {
    color: #9e9e9e !important;
  }
  > span {
    color: #9e9e9e !important;
  }
}

.YoelloButton {
  background: #ffdd02;
  border-radius: 0.625rem;
  /* box-shadow: 0px 0px 4.12308px rgba(0, 0, 0, 0.1); */
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5625rem 1.125rem;
  cursor: pointer;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    color: #212121;
    margin: 0;
  }
  > span {
    font-size: 1.5rem;
  }
}

.Clickable {
  cursor: pointer;
}
