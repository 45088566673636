.ChoiceBoxContainer {
  background: #f9f9f9;
  border-radius: 0.875rem;
  border-bottom: 0.70775px solid #ffffff;
  padding: 1rem 1.5625rem;
  margin: 0.5rem 0;
  position: relative;
  .ChoiceBoxInnerDiv {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > div:first-child {
        margin-left: -1rem;
        margin-right: 1rem;
        padding-bottom: 2px;
      }
    }
  }
  .ExtrasAllergens {
    position: absolute;
    right: -1rem;
    // font-size: 1.7rem;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
    // color: #1d8bf7;
    cursor: pointer;
    height: 32px;
    width: 32px;
    justify-content: center;
    background-color: #f1f9ff;
    border-radius: 8px;
  }
  .ChoiceBoxName {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    color: #424242;
    margin: 0;
  }
  // .ExtraChoiceBoxName {
  //   width: 70%;
  // }
  .AddButton {
    background: #faf6e0;
    border: 1px solid #ffdd02;
    border-radius: 6px;
    height: 30px;
    > p {
      font-size: 0.9375rem !important;
    }
  }
}

.ChoiceBoxContainerDisabled {
  background: #eeeeee !important;

  .ChoiceBoxName {
    color: #bdbdbd !important;
  }
}

.Selected {
  background: linear-gradient(
      0deg,
      rgba(255, 221, 2, 0.1),
      rgba(255, 221, 2, 0.1)
    ),
    #f9f9f9 !important;
  border: 1px solid #ffdd02 !important;
  box-sizing: border-box !important;
  .ChoiceBoxName {
    font-weight: 600;
  }
}
.SelectedBoxLine {
  background: #ffdd02;
  border-radius: 0.75rem;
  width: 0.25rem;
  margin-right: -0.5rem;
  margin-left: 0.25rem;
  height: 1.25rem;
}

.SelectedBoxCheckBox {
  background: #ffdd02;
  border-radius: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  position: absolute;
  right: -0.6875rem;
  top: 0;
  bottom: 0;
  margin: auto;
  > span {
    font-size: 1.5rem;
  }
}

.InfoIcon {
  // background-color: rgb(232, 243, 254);
  height: 1.25rem;
  width: 1.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d8bf7;
  font-size: 2rem;
}
// @media only screen and (max-width: 360px) {
//   .ExtraChoiceBoxName {
//     width: 65% !important;
//   }
// }
