$yellow-filters-color: #ffdd02;

.Login {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 1.875rem 2.5rem;
  .BackButtonDiv {
    position: absolute;
    top: 1.875rem;
    left: 2.25rem;
    z-index: 9999;
  }

  .Text1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    color: #212121;
  }

  .Text2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #212121;
  }

  .InputGuideText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    color: #737fa4;
    padding: 0.125rem 0.25rem;
  }

  .ContinueButton {
    background: #ffdd02;
    border-radius: 0.625rem;
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      color: #212121;
      margin: 0;
    }
    > span {
      font-size: 1.5rem;
    }
  }

  .SectionDivs {
    margin-top: 3.4rem;
  }

  .OtpInput::placeholder {
    letter-spacing: 0px !important;
    /* padding: 0 20px; */
    // height: 3.4375rem;
    font-size: 1rem;
    text-align: center;
  }

  .OtpInput {
    background: #f9f9f9;
    border-radius: 0.625rem;
    padding: 0;
    width: 100%;
    height: 3.4375rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 1rem;
    color: #212121;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .verificationContainer {
    input::-webkit-input-placeholder {
      line-height: normal !important;
    }
    > input {
      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }
    }
  }

  .ResendCode {
    margin-top: 0.625rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    color: #737fa4;
    transition: 0.25s ease-in-out;
    &.Disabled {
      opacity: 0.6;
      & > strong {
        padding-left: 0.625rem;
        cursor: default;
      }
    }

    & > strong {
      padding-left: 0.625rem;
      cursor: pointer;
    }
  }

  .InputErrorText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.834675rem;
    line-height: 1rem;
    color: #ff0000;
    margin-bottom: 0;
    padding-left: 0.3125rem;
  }

  .ErrorImage {
    width: 1.875rem;
    height: 1.875rem;
    overflow: hidden;
    position: absolute;
    right: 1.875rem !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 100%;
  }

  .SignUpInputDivsError {
    border: 2px solid #ef1401 !important;
  }

  .SectionDivs2Error {
    border: 2px solid #ef1401 !important;
  }

  .SignUpInputDivs {
    width: 100%;
    /* border: 2px solid #FAC000; */
    background: #f9f9f9;
    border-radius: 0.625rem;
    height: 3.125rem;
    position: relative;
    > span {
      position: absolute;
      left: 0.625rem;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 1.8rem;
      height: fit-content;
      color: #bdbdbd;
      display: flex;
      align-items: center;
    }
  }

  .SignUpInput {
    background: transparent !important;
    border: none;
    box-sizing: border-box;
    border-radius: 0;
    height: 100%;
    text-align: left;
    padding-left: 2.8125rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #212121;
    font-weight: 600;
    width: 100%;
  }

  .SignUpInputDob {
    background: transparent !important;
    border: none;
    box-sizing: border-box;
    border-radius: 0;
    height: 100%;
    text-align: left;
    padding-left: 0.125rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.125rem;
    color: #000000;
    width: 100%;
  }

  .TermsConditions {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    color: #737fa4;
    margin: 0.375rem 0;
    > a {
      color: #4f3cc9 !important;
      font-weight: bold;
    }
  }

  .marketingOptDiv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1.5625rem;
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9375rem;
      color: #212121;
      margin-bottom: 0;
      margin-left: 8px;
    }
  }
  .checkboxWrapper {
    margin: 1.375rem 0;
    > label {
      display: flex;
      justify-content: flex-start;
      > span:first-child {
        margin-top: 6px;
        outline: unset;
        height: fit-content;
      }
      > span:last-child {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9375rem;
        line-height: 1.625rem;
      }
    }
  }

  :global(.react-date-picker__inputGroup__leadingZero) {
    display: none !important;
  }

  :global(.react-date-picker__inputGroup input) {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 1rem;
    color: #212121;
  }
  :global(.react-date-picker__inputGroup__month) {
    width: 3.5rem !important;
  }
  :global(.react-date-picker__inputGroup__year) {
    width: 4.5rem !important;
  }
  :global(.react-date-picker__inputGroup__day) {
    width: 3.5rem !important;
  }

  :global(.react-date-picker__wrapper) {
    border: none !important;
    margin-left: 2rem;
  }
  :global(.react-date-picker__wrapper input) {
    color: #212121;
  }
  :global(.react-date-picker__wrapper input::placeholder) {
    color: #bdbdbd;
  }

  :global(.react-date-picker__button__icon) {
    // display: none !important;
    stroke: #9e9e9e !important;
  }

  :global(.react-tel-input) {
    background-color: #f9f9f9;
    border-radius: 0.625rem;
    border: none;
  }

  :global(.react-tel-input input) {
    background-color: #f9f9f9;
    border-radius: 0.625rem;
    height: 3.4375rem;
    padding-left: 3.75rem;
    width: 100%;
    border: none;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    color: #212121;
  }

  :global(.flag-dropdown) {
    margin: 0.625rem;
    background: #f1f1f1 !important;
    border-radius: 0.5rem !important;
  }

  ::global(.selected-flag) {
    background: #f1f1f1 !important;
  }
}
