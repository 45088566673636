@mixin texts-css($collor, $fontSize, $fontWeight, $lineHeight) {
  font-family: Open Sans;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $collor;
  line-height: $lineHeight;
  margin: 0;
}

.OrderTypeDiv {
  background: #f9f9f9;
  padding: 0.9375rem 1.875rem;
  border-radius: 10px;
  margin: 0.625rem 0;
}

.VenueOrderTypes {
  margin-top: 1rem;
  .OrderTypeInfoDiv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .OrderTypeInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.9375rem;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .OrderTypeInfoValue {
      @include texts-css(#737fa4, 0.9375rem, 600, 1.25rem);
      margin: 0 2px;
    }

    .OrderTypeInfoKey {
      @include texts-css(#737fa4, 0.8125rem, 400, 1.125rem);
      margin: 0 2px;
    }
    .OrderTypeInfoSpacing {
      width: 2rem;
    }
  }
}

.OrderTypeHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > p {
    font-family: Open Sans;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: #212121;
    margin: 0;
  }
  > svg {
    margin-right: 1.3125rem;
    max-height: 28px;
    max-width: 23px;
  }
}
.description {
  font-size: 0.9375rem;
  margin: 1.5rem 0;
  > span {
    color: #212121;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 0.375rem;
  }
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    line-height: 1.625rem;
    display: flex;
    align-items: center;
    color: #737fa4;
    letter-spacing: -0.025rem;
  }
}
:global(#VenueOrderTypes .ant-collapse) {
  background: transparent;
  border: none;
  padding-left: 0 !important;
}

:global(#VenueOrderTypes
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header) {
  padding-left: 0 !important;
}

:global(#VenueOrderTypes .ant-collapse-content-box) {
  background-color: #f9f9f9;
}

:global(#VenueOrderTypes .ant-collapse > .ant-collapse-item) {
  border: none;
  margin: 0.625rem 0;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
}

:global(#VenueOrderTypes .ant-collapse-content) {
  border: none;
}

:global(#VenueOrderTypes
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow) {
  right: 30px;
  left: unset !important;
}
