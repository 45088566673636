.slideright-enter {
  position: absolute;
  transform: translateX(100%);
}
.slideright-enter-active {
  transform: translateX(0%);
  transition: transform 250ms ease-in-out;
}
.slideright-exit {
  transform: translateX(0%);
}
.slideright-exit-active {
  transform: translateX(-100%);
  transition: transform 250ms ease-in-out;
}
.slideleft-enter {
  position: absolute;
  transform: translateX(-100%);
}
.slideleft-enter-active {
  transform: translateX(0%);
  transition: transform 250ms ease-in-out;
}
.slideleft-exit {
  transform: translateX(0%);
}
.slideleft-exit-active {
  transform: translateX(100%);
  transition: transform 250ms ease-in-out;
}
