.leavingWarningContainer {
  flex: 0 0 5.625rem;
  position: absolute;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    .lineBar {
      width: 2.625rem;
      height: 0.3125rem;
      background: #e8eaf4;
      border-radius: 2.5625rem;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 0.875rem;
    }
  }

  .warningDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 1.5rem;
    > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;
    }
    div.icon {
      margin-bottom: 2rem;
    }

    > p {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #212121;
    }
    p:first-of-type {
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      .continue {
        width: 48%;
        height: 50px;
        background: #ffffff;
        border: 1px solid #fb3449;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;

        color: #fb3449;
      }
      .cancel {
        width: 48%;
        height: 50px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        border: none;
        background: #f9f9f9;
        border-radius: 8px;
      }
    }
    .closeDrawer {
      border-radius: 50%;
      color: #aab6d3;
      font-size: 1.5rem;
      width: 2rem;
      height: 2rem;
      background: unset;
      position: inherit;
    }
  }

  .leavingWarningDrawer {
    max-width: 768px;
    height: 400px;
    bottom: 0;
    position: fixed;
    width: 100%;
    border-top-right-radius: 2.25rem;
    border-top-left-radius: 2.25rem;
    background-color: #fff;
    z-index: 3;
    transform: translate3d(0, 100%, 0);
    -webkit-transition: transform 300ms ease-in-out;
    -moz-transition: transform 300ms ease-in-out;
    -ms-transition: transform 300ms ease-in-out;
    -o-transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
  }

  .openWarning {
    transform: translate3d(0, 0, 0);
  }

  .drawerMask {
    height: 100vh !important;
    display: none;
    width: 100%;
    max-width: 768px;
    bottom: 0;
    position: fixed;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .maskBackground {
    display: block;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    z-index: 2;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
}
