.externalLinksContainer {
  margin-top: 1.875rem;
  margin-bottom: 2.5rem;
  > header {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375;
    color: #212121;
    margin: 0 0 0.938rem 0;
  }
  > ul {
    padding: 0;
    > li {
      list-style: none;
      margin-bottom: 0.625rem;
      a,
      a:hover,
      a:active,
      a:visited,
      a:focus {
        text-decoration: none !important;
      }
      > a {
        border: 1.5px solid #eeeeee;
        border-radius: 10px;
        padding: 0.938rem 1.313rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
          width: 90%;
          > svg {
            width: 1.625rem;
            height: 1.625rem;
            min-width: 1.625rem;
            min-height: 1.625rem;
            max-width: 1.625rem;
            max-height: 1.625rem;
          }
          > span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 1.063rem;
            line-height: 1.438rem;
            color: #212121;
            padding-left: 1.313rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        > span {
          color: #212121;
          font-family: Open Sans;
          font-style: normal;
          font-size: 1.5rem;
        }
      }
    }
  }
}
