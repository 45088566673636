.menuItemsPageContainer {
  width: 100%;
  position: relative;
  height: 100vh;
  .header {
    width: 100%;
    max-width: 768px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    background: #fff;
    .headerBtn {
      width: 2.5rem;
      height: 2.5rem;
      > svg {
        width: 100%;
        height: 100%;
        border-radius: 0.625rem;
        rect {
          width: 100%;
        }
      }
    }
    .headerImg {
      width: 8rem;
      height: 1.75rem;
      > svg {
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        rect {
          width: 100%;
        }
      }
    }
  }
  .topMenuContainer {
    height: calc(100vh - 5.625rem);
    .topMenu {
      height: 6.25rem;
      width: 100%;
      :global(.ant-divider-horizontal) {
        margin: unset;
      }
      .mainCats {
        margin: 1.375rem 3.125rem 0 3.125rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div {
          height: 2.5rem;
          width: 6.25rem;
          padding: 0 0.75rem;
          > svg {
            width: 100%;
            height: 100%;
            border-radius: 0.625rem;
            rect {
              width: 100%;
            }
          }
        }
      }
      .subCats {
        margin-top: 1.375rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow-x: auto;
        width: 100%;
        div {
          height: 2.5rem;
          width: 5.8125rem;
          padding: 0 0.75rem;
          > svg {
            width: 100%;
            height: 100%;
            border-radius: 0.625rem;
            rect {
              width: 100%;
            }
          }
        }
      }
    }
    .menuContents {
      margin-top: 1.5625rem;
      div {
        margin: 0 1.25rem 0.9375rem 1.25rem;
        height: 6.25rem;
        > svg {
          width: 100%;
          height: 100%;
          border-radius: 1.125rem;
          rect {
            width: 100%;
          }
        }
      }
    }
  }
  .myCart {
    height: 5.625rem;
    position: fixed;
    max-width: 768px;
    bottom: 0;
    width: 100%;
    z-index: 111;
    > svg {
      width: 100%;
      height: 100%;
      rect {
        width: 100%;
      }
    }
  }
}
