.Home {
  background: #ffffff;
  width: 100%;
  height: 100%;
  transition: 1s all ease-in;
  /* position: fixed; */
  top: 0;
  overflow: hidden;
  max-width: 768px;
}

.Home .Text1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14.8068px;
  text-align: center;
  text-transform: uppercase;
  color: #374a6c;
  opacity: 0.5;
  margin: 0;
  width: fit-content;
  margin: auto;
}

.Home .Text2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #334669;
  margin: 0;
  width: fit-content;
  margin: auto;
}

.Home .TopBox {
  box-sizing: border-box;
  box-shadow: 0px 0px 28.6697px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 6px;
  padding-top: 12px;
}
/* .ReactModal__Overlay{
    transition: 1s all ease-in
} */

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  /* background: linear-gradient(0deg, rgba(250, 192, 0, 0.8), rgba(250, 192, 0, 0.8)) !important; */
  transition: opacity 350ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
}

.AnimationLogo {
  transition: 2s all ease-in-out;
  position: absolute;
  z-index: 999999;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

#SWIPEDRAWER .MuiDrawer-paperAnchorBottom {
  top: 0 !important;
}

#PaymentsConfirmOrder .MuiDrawer-paperAnchorBottom {
  /* top: 110px !important; */
  background: #fff;
  box-shadow: 24.9333px 27.9253px 65.824px rgba(176, 195, 210, 0.727846);
  border-radius: 23.936px 23.936px 0px 0px;
}
#PaymentsConfirmOrder .MuiDrawer-paper {
  height: calc(100vh - 10.0625rem) !important;
  overflow-y: unset !important;
  border-radius: 2.25rem 2.25rem 0 0 !important;
}

#OrderQrCode .MuiDrawer-paperAnchorBottom {
  /* top: 110px !important; */
  background: #fff;
  box-shadow: 24.9333px 27.9253px 65.824px rgba(176, 195, 210, 0.727846);
  border-radius: 23.936px 23.936px 0px 0px;
}
#OrderQrCode .MuiDrawer-paper {
  height: calc(100vh - 7.5rem) !important;
  overflow-y: unset !important;
  border-radius: 2.25rem 2.25rem 0 0 !important;
}

.Home .DiscountText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #334669;
  mix-blend-mode: normal;
  opacity: 0.6;
  margin: 20px 0;
}
.MenuItemsContainer {
  padding: 0 24px;
  margin-top: 2px;
  height: calc(100% - 165px);
  overflow-y: scroll;
}
@media only screen and (max-height: 650px) {
  .Home .Text1 {
    font-size: 10px;
  }

  .Home .Text2 {
    font-size: 16px;
  }

  .MenuItemsContainer {
    height: calc(100% - 145px);
  }
  .Home .DiscountText {
    font-size: 12px;
    margin: 10px 0 -5px 0;
  }
}
