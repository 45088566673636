.orderStatusContainer {
  display: flex;
  p {
    font-family: Open Sans;
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    min-width: 4.5rem;
    justify-content: center;
  }
}
.STARTED {
  color: #46a6f0;
  background-color: #bad4e8;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.PLACED {
  color: #7033ff;
  background-color: #ded9fb;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.ACCEPTED {
  color: #24c277;
  background-color: #d8f0ea;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.FINISHED {
  color: #8824c2;
  background-color: #e3d8f0;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.REFUNDED {
  color: #4e7cff;
  background-color: #d9e4fb;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.REFUND_FAILED {
  color: #ea4eff;
  background-color: #f8d9fb;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.CLOSED {
  color: #ff4e83;
  background-color: #fbd9e9;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.REJECTED {
  color: #ff4e4e;
  background-color: #fbd9d9;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}

.PROCESSING_REFUND {
  color: #4e7cff;
  background-color: #d9e4fb;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}

.PARTIALLY_REFUNDED {
  color: #fa7800;
  background-color: #feeee0;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}

.DECLINED {
  color: #f21136;
  background-color: #e8baba;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.VOID {
  color: #ec2525;
  background-color: #ff9d9d;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.READY_FOR_COLLECTION {
  color: #1d8bf7;
  background-color: #ddeefe;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
.PAYMENT_PROCESSING {
  color: #737fa4;
  background-color: #e8eaf4;
  padding: 0.625rem 0.9375rem;
  border-radius: 3.5rem;
}
