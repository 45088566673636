/* .ReactVirtualized__Grid__innerScrollContainer{
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
    height: 100% !important;
} */
.itemsListContainerId {
  margin: 0;
  display: flex;
  justify-content: center;
  height: 100%;
}

.listFlexWrapper {
  flex: 1 1 auto;
  position: relative;
}

.heightWrapper {
  width: 100%;
  height: 100%;
}

.menueItemsList {
}
.menueItemsList {
  > div {
    div {
      height: 6.25rem !important;
    }
  }
}
.Grid {
  padding-bottom: 3.125rem;
}
.contentSlideToTop {
  .shadowTop {
    height: 6.25rem;
    background-color: #fff;
    position: absolute;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    border-radius: 1.25rem;
    left: 0;
    z-index: 1;
  }
}
.contentSlideToBottom {
  .shadowBottom {
    height: 6.25rem;
    background-color: #fff;
    position: absolute;
    bottom: 3.75rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    border-radius: 1.25rem;
    width: 100%;
    left: 0;
    z-index: 1;
  }
}
