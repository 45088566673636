.orderHistoryCardContainer {
  margin: 0 1.25rem;
  padding: 0.9375rem 1.5625rem;
  height: 7.5rem;
  background: #f9f9f9;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  .orderDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      margin: 0;
      margin-bottom: 0.3125rem;
      font-family: Open Sans;
      font-style: normal;
      color: #212121;
    }
    > div:first-child {
      .orderId {
        font-weight: 600;
        font-size: 1.0625rem;
        line-height: 1.4375rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.0625rem;
      }
      .orderDateText {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: #757575 !important;
      }
    }
    > div:last-child {
      .price {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
        display: flex;
        align-items: center;
        text-align: right;
      }
    }
  }
  .orderVanueAndStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    > div:first-child {
      display: flex;
      flex: 1 1 100%;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      > img {
        max-width: 1.875rem;
        max-height: 1.875rem;
        min-width: 1.875rem;
        min-height: 1.875rem;
        border-radius: 0.5rem;
        margin-right: 0.6875rem;
      }
      > p {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .placeName {
        margin: 0;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.375rem;
        // display: flex;
        align-items: center;
      }
    }
    > div:last-child {
      display: flex;
      flex: 1 0 auto;
      flex-direction: row;
      align-items: center;
      > div {
        margin-left: auto;
        display: flex;
        align-items: center;
        svg {
          height: 1rem;
        }
        > span {
          margin-left: 1rem;
        }
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 0.8125rem;
          line-height: 1.125rem;
          display: flex;
          align-items: center;
          text-align: center;
          margin: 0;
        }
      }

      .PLACED {
        color: #7033ff;
        background-color: #ded9fb;
        padding: 0.3125rem 0.625rem;
        border-radius: 3.5rem;
      }
      .FINISHED {
        color: #24c277;
        background-color: #d8f0ea;
        padding: 0.3125rem 0.625rem;
        border-radius: 3.5rem;
      }
      .REFUNDED {
        color: #4e7cff;
        background-color: #d9e4fb;
        padding: 0.3125rem 0.625rem;
        border-radius: 3.5rem;
      }
      .CLOSED {
        color: #ff4e83;
        background-color: #fbd9e9;
        padding: 0.3125rem 0.625rem;
        border-radius: 3.5rem;
      }
      > span {
        margin-left: 1rem;
        > svg {
          height: 1rem;
          width: 0.5625rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .orderHistoryCardContainer {
    height: 8.25rem;
  }
}
@media only screen and (max-width: 321px) {
  .orderHistoryCardContainer {
    height: 9.5rem;
    justify-content: center;
  }
}
