.notFoundPageContainer {
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  .sidemenuToggleBtn {
    top: 1.875rem;
    right: 1.875rem;
  }
  .yoelloIcon {
    padding-top: 6.875rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 3.9375rem;
      height: 3.875rem;
    }
  }
  .yoelloLogo {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 5.1875rem;
      height: 3.125rem;
    }
  }
  .notFoundSvg {
    margin: 3.75rem 3.125rem 3.375rem 3.125rem;
    max-height: 10.75rem;
    min-height: 10.75rem;
    display: flex;
    justify-content: center;
  }
  .descriptions {
    padding-top: 2.5rem;
    margin: 0 2.5rem;
    > pre:first-child {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 2.375rem;
      display: flex;
      align-items: center;
      color: #212121;
      margin: 0;
    }
    > p {
      height: 3.75rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.875rem;
      display: flex;
      align-items: center;
      color: #212121;
      margin: 0;
      padding-top: 1.5625rem;
    }
  }
  .contactUsBtncontiner {
    margin: 2.5rem 2.5rem;
    > a {
      color: unset !important;
      text-decoration: none !important;
      background-color: transparent !important;
      .contactUs {
        padding: 1.0625rem 6rem;
        background: #ffdd02;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        > p {
          height: 1.375rem;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.375rem;
          display: flex;
          align-items: center;
          color: #212121;
          margin: 0;
        }
        > span {
          padding-left: 0.5rem;
          font-size: 1.25rem;
        }
      }
    }
  }
}
