.ProductDetailsContainer {
  height: 100%;
}
.ProductDetails {
  padding: 0 1.5rem;
  .ImageDiv {
    width: 100%;
    height: 8.75rem;
    border-radius: 0.9375rem;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;

    > img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      border-radius: 1.25rem;
    }
  }

  .Descritpion {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    color: #757575;
    text-align: center;
    width: 100%;
    margin-top: 0.6rem;
    margin-bottom: 2px;
    white-space: pre-wrap;
  }

  .Title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1875rem;
    color: #212121;
    text-align: left;
    margin: 1.1rem 0.9rem 0 0.9rem;
  }
}

.PricingOptionDiv {
  background: #f9f9f9;
  border-radius: 0.875rem;
  border-bottom: 0.70775px solid #ffffff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.875rem 1.5rem;
  margin: 1.25rem 0;
}

.PricingOptionDivSelected {
  background: linear-gradient(
      0deg,
      rgba(255, 221, 2, 0.1),
      rgba(255, 221, 2, 0.1)
    ),
    #f9f9f9 !important;
  border: 1px solid #ffdd02 !important;
  box-sizing: border-box !important;
  > p {
    font-weight: 600;
  }
  .PricingOptionCurrentPrice {
    font-weight: 600;
  }
}

.PricingOptionName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375rem;
  color: #212121;
  margin: 0;
}

.PricingOptionCurrentPrice {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.0625rem;
  color: #212121;
  margin: 0;
}
.PricingOptionOldPrice {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375rem;
  text-decoration-line: line-through;
  color: #9e9e9e;
  margin: 0 0.375rem 0 0;
}

.DiscountDiv {
  background: #ffdd02;
  border-radius: 2rem;
  width: 1.875rem;
  height: 1.125rem;
  text-align: center;
  margin-right: 0.375rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6875rem;
  color: #212121;
}
.AllergensBtn {
  text-align: center;
  display: flex;
  align-items: center;
  /* margin-left: 12px; */
  justify-content: center;
  /* width: 5.3125rem; */
  height: 2.5rem;
  border-radius: 0.75rem;
  padding: 0.625rem;
  background: #f9f9f9;
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    color: #737fa4;
    margin: 0;
  }
}
.SelectedOptionLine {
  background: #ffdd02;
  border-radius: 0.75rem;
  width: 0.25rem;
  margin-right: -0.75rem;
  margin-left: 0.625rem;
  height: 1.25rem;
}

.ProductTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #212121;
  margin: 0;
}

.PageTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1875rem;
  line-height: 1.625rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #737fa4;
  margin: 0;
}

:global(.ant-carousel .slick-dots li button) {
  background-color: #aab6d3;
}

.InfoBottomText {
  // position: fixed;
  background: #f1f9ff;
  border-radius: 0.875rem;
  bottom: 1.875rem;
  margin: 0 30px;
  > div:first-child {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    margin-top: 1.25rem;
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      color: #212121;
      margin: 0;
      padding-left: 0.625rem;
    }
    > i {
      color: #1d8bf7;
      font-size: 1.75rem;
    }
  }
  > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    line-height: 1.625rem;
    display: flex;
    align-items: center;
    color: #616161;
    margin: 0.625rem 1.625rem 1.25rem 1.625rem;
  }
}

.productDetailsPageTitle {
  color: #000000 !important;
}

.drawerHeader {
  display: flex;
  flex-direction: column;
  padding: 0.9375rem 1.5625rem 0 1.5625rem;
  border-bottom: 1px solid #e8eaf4;
  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.9375rem;
    > span {
      width: 42px;
      height: 5px;
      background: #e8eaf4;
      border-radius: 41px;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.9375rem;
    align-items: center;
    > div:first-child {
      flex: 1;
    }
    > p {
      display: inline-block;
      flex: 3;
      align-self: center;
    }
    > div:last-child {
      flex: 1;
    }
  }
}
.container {
  height: calc(100% - 7rem);
  height: -webkit-calc(100% - 7rem);
  overflow-y: auto;
}
.detailsWrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 3rem;
}

.TopHeaderSection {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  > div:first-child {
    margin-right: auto;
  }
  > p {
    margin-right: 1.75rem;
  }
  > div:last-child {
    margin-left: auto;
  }
}

.allergensDrawerContainer {
  > div:last-child {
    height: calc(100vh - 21.25rem) !important;
    overflow: auto !important;
    margin: 0 !important;
    padding: 1.875rem 2.5rem !important;
  }
}
