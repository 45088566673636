$OpenColor: #65ff78;
$CloseColor: #ff658b;
.venueContainer {
  // width: 100%;
  // height: 100%;
  overflow: hidden;
  font-size: 1rem;
  max-width: 768px;
  display: flex;
  flex-flow: column;
  background-size: 100% 41% !important;
  background-repeat: no-repeat !important;
  height: 100vh;
  // position: relative;
  p {
    margin: 0;
    padding: 0;
  }

  .venueImage {
    height: 17rem;
    // transition: all 0.25s ease-out;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    position: relative;
    background-size: cover;
    background-position: top center;
    .remote {
      height: 100%;
      width: 100%;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .venueSidemenuToggleBtn {
      top: 1.875rem;
      right: 1.875rem;

      background: #ffffff !important;
      > svg {
        > line {
          stroke: #000000;
        }
      }
    }
    .venueSidemenuToggleBtnBlack {
      top: 1.875rem;
      right: 1.875rem;
      > svg {
        > line {
          stroke: #ffffff;
        }
      }
    }
    .venueBackBtn {
      top: 1.875rem;
      left: 1.875rem;
      background: #ffffff !important;
      > svg {
        > path {
          fill: #000000;
        }
      }
    }
    .venueBackBtnBlack {
      top: 1.875rem;
      left: 1.875rem;
      > svg {
        > path {
          fill: #ffffff;
        }
      }
    }
  }
  .venueDetails {
    // position: absolute;
    width: 100%;
    // left: 0px;
    // top: 12rem;
    background: #ffffff;
    border-radius: 2.25rem 2.25rem 0 0;
    height: 100%;
    // height: calc(100vh - 11.875rem);
    transform: translate3d(0, -30px, 0);
    -webkit-transition: transform 300ms ease-in-out;
    -moz-transition: transform 300ms ease-in-out;
    -ms-transition: transform 300ms ease-in-out;
    -o-transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    .logo {
      > img {
        // transition: all 0.25s ease-out;
        position: fixed;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        // position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        left: 2.5rem;
        top: -1.875rem;
        border-radius: 0.75rem;
        z-index: 20;
      }
      > svg {
        position: fixed;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        // position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        left: 2.5rem;
        top: -1.875rem;
        border-radius: 0.75rem;
        z-index: 20;
      }
    }
    .lineBar {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 1rem;
      > span {
        width: 2.625rem;
        background-color: #e8eaf4;
        height: 0.3125rem;
        position: absolute;
        top: 0.625rem;
      }
    }
    .share {
      display: flex;
      flex-direction: row;
      position: absolute;
      right: 2.5rem;
      top: 1.25rem;
      font-size: 1rem;
      color: #8d98b7;
      align-items: center;
      > p {
        margin-left: 0.3125rem;
      }
      > span {
        font-size: 1.5rem;
      }
    }
    .venueContent {
      padding: 0 2.5rem;
      margin: 3.5rem 0 0 0;
      height: calc(100vh - 18rem);
      overscroll-behavior: none;
      scroll-behavior: smooth;
      overflow: auto;
      .venueNameAndStatus {
        display: flex;
        flex-direction: row;
        // margin: 40px 40px 0 40px;
        align-items: center;
        color: #2deb62;
        margin-bottom: 0.625rem;
        margin-top: 0.25rem;
        > div:last-child {
          display: flex;
          flex-direction: row;
          margin-left: 0.75rem;
          line-height: 1.25rem;
          height: 1.25rem;
          align-items: center;
          > p {
            margin-left: 0.625rem;
          }
          .openCircleRipple {
            background-color: #35ffc3;
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            animation: GreenRipple 0.7s linear infinite;
          }
          .closeCircleRipple {
            background-color: #ff3578;
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            animation: RedRipple 0.7s linear infinite;
          }
        }
        .closeText {
          color: #ff4f4f !important;
        }
      }
      .addressName {
        margin: 0.25rem 0;
        font-size: 0.9375rem;
        color: #8d98b7;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        > span {
          font-size: 1.5rem;
          position: absolute;
          left: -0.375rem;
        }
        > p {
          padding-left: 1.125rem;
        }
      }

      .shareButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 1.6875rem 0 1rem 0;
        padding-bottom: 8.125rem;
        > a{
          margin: 0.75rem;
        }
      }
      .venueInfo {
        margin: 1.5rem 0;
        display: flex;
        justify-content: space-between;
      }
      .description {
        font-size: 0.9375rem;
        margin: 1.5rem 0;
        > span {
          color: #212121;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.375rem;
          margin-bottom: 0.375rem;
        }
        > p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 0.9375rem;
          line-height: 1.625rem;
          display: flex;
          align-items: center;
          color: #737fa4;
          letter-spacing: -0.025rem;
          white-space: pre-wrap;
        }
      }

      .AlcoholTextDiv {
        margin: 1.5rem 0;
        padding: 1.375rem 1.5rem;
        background: #f1f9ff;
        border-radius: 0.875rem;
        > div:first-child {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 0.9375rem;
          color: #212121;
          margin: 0;
          display: flex;
          align-items: center;
          position: relative;
          height: 1.5rem;
          > span:first-child {
            color: #1d8bf7;
            font-size: 1.5rem;
            position: absolute;
            left: -0.375rem;
          }
          > span:last-child {
            position: absolute;
            left: 1.25rem;
          }
        }
        .AlcoholText {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 0.9375rem;
          color: #616161;
          margin: 0;
        }
      }
      .ShareText {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        color: #8d98b7;
        margin: 0;
      }

      .VenueNameText {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        // line-height: 0px;
        font-size: 1.4375rem !important;
        color: #212121;
        margin: 0;
        width: 100%;
      }
      .venueTradingTimes {
        margin: 1.5rem 0;
      }
    }
  }
  .viewMenuBtnWrapper {
    background-color: #ffdd02;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 768px;
    margin: auto;
    height: 4.5rem;
    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
      text-decoration: none !important;
    }

    .viewMenuBtn {
      display: flex;
      flex-direction: row;
      color: #000000;
      margin-top: 0;
      padding: 1.55rem;
      align-items: center;
      > p {
        margin: 0;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.0625rem;
        line-height: 0px;
        /* identical to box height, or 0% */
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        height: 1.4375rem;
      }
      > span {
        color: #000000;
        font-style: normal;
        font-weight: 600;
        font-size: 1.0625rem;
        padding-left: 1rem;
      }
    }
  }
}

.withBlurImage {
  .topShadow {
    height: 5rem;
    background-color: #fff;
    position: absolute;
    width: 100%;
    top: 3.37rem;
    left: 0;
    margin: auto;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    border-radius: 0px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .venueImage {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    overflow: hidden;
    /* Add the blur effect */

    .remote {
      filter: blur(2px);
      -webkit-filter: blur(2px);
    }
  }
  .venueDetails {
    // height: 100%;
    // top: 6.875rem;
    // z-index: 1;
    // transition: all 0.25s ease-in;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    transform: translate3d(0, -3.5rem, 0);
    -webkit-transform: translate3d(0, -3.5rem, 0);
    padding-bottom: 4rem;
    .venueContent {
      // min-height: 100%;
      height: calc(100% + 1rem);
      // height: fit-content;
      // padding-bottom: 15px;
      // margin-bottom: 5rem;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior-y: none;
      // height: 100%
      // -webkit-overflow-scrolling: touch;
      // overflow-x: unset;
    }
  }
}
.languageWrapper {
  position: absolute;
  top: 2.6875rem;
  right: 5.5rem;
  height: 1.25rem;
}
@keyframes GreenRipple {
  0% {
    box-shadow: 0 0 0 0 rgba($OpenColor, 0.3), 0 0 0 0.1em rgba($OpenColor, 0.3),
      0 0 0 0.3em rgba($OpenColor, 0.3), 0 0 0 0.5em rgba($OpenColor, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.1em rgba($OpenColor, 0.3),
      0 0 0 0.3em rgba($OpenColor, 0.3), 0 0 0 0.5em rgba($OpenColor, 0.3),
      0 0 0 0.8em rgba($OpenColor, 0);
  }
}
@keyframes RedRipple {
  0% {
    box-shadow: 0 0 0 0 rgba($CloseColor, 0.3),
      0 0 0 0.1em rgba($CloseColor, 0.3), 0 0 0 0.3em rgba($CloseColor, 0.3),
      0 0 0 0.5em rgba($CloseColor, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.1em rgba($CloseColor, 0.3),
      0 0 0 0.3em rgba($CloseColor, 0.3), 0 0 0 0.5em rgba($CloseColor, 0.3),
      0 0 0 0.8em rgba($CloseColor, 0);
  }
}

@media only screen and (min-height: 640px) {
  .shareButtons {
    padding-bottom: 6.125rem !important;
  }
}
@media only screen and (min-width: 1000px) {
  .venueContent {
    overflow: auto;
  }
}
