.WarningComponent {
  padding: 30px 40px;
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .WarningComponentHeaderDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > p {
      margin: 0;
      margin-left: 10px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      color: #ff862f;
    }
  }

  .DescriptionText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #212121;
    margin: 0;
    margin-top: 1.25rem;
  }
}
