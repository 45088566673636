.CountDiv {
  background: #ffdd02;
  border-radius: 8px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  .CountText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #212121;
    margin: 0;
  }
}
.CountChangebleDiv {
  background: none !important;
  width: auto;
  height: auto;
  svg {
    width: 1rem;
    height: 1.375rem;
  }
  .CountText {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 1.25rem;
  }
}
