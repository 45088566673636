.PayButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fffce6;
  border: 1.5px solid #ffdd02;
  border-radius: 12px;
  color: #212121;
  width: 100%;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0.5rem 0;
  position: relative;
  justify-content: center;
  .Loader {
    height: 2rem;
    width: 80%;
  }
  > p {
    padding: 0;
    margin: 0;
    // width: 100%;
  }
  > svg:first-child {
    position: absolute;
    left: -1rem;
  }
  .rightChevron {
    position: absolute;
    right: 1.5rem;
  }
  // >svg{
  //     float: right;
  //     order:2;
  //     margin-left: auto;
  // }
}

.InfoDrawerInstructions {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1d8bf7;
}
.InfoDrawerContainer {
  padding: 0 1.5rem 1rem 1.5rem;
  > p {
    margin: 0.25rem 0;
    padding: 0.1rem;
  }
}

.disabled {
  border-color: #ccc;
}
