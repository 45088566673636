.supportContainer {
  height: 15.375rem;
  border-radius: 36px 36px 0 0;
  .lineBar {
    width: 100%;
    height: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.9375rem;
    margin-bottom: 1.25rem;
    > span {
      width: 42px;
      height: 100%;
      background-color: #e8eaf4;
      border-radius: 41px;
    }
  }
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
    > div:first-child {
      display: flex;
      align-items: center;
      > p {
        margin: 0;
        padding-left: 0.625rem;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.6875rem;
        display: flex;
        align-items: center;
        color: #212121;
      }
      > svg {
        width: 1.1875rem !important;
        height: 1.125rem !important;
      }
    }
  }
  > p {
    padding: 1.25rem 2.5rem;
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #212121;
  }
}
