.BottomBar {
  background: #ffffff;
  box-shadow: 0px -6px 6px -6px rgba(52, 53, 66, 0.12);
  padding: 1rem 1.5rem;
  height: 8rem;
  text-align: center;
  // border-bottom-left-radius: 1.5625rem;
  // border-bottom-right-radius: 1.5625rem;

  .ContinueButton {
    background: #ffdd02;
    border-radius: 0.625rem;
    padding: 0.6rem 0.8rem;
  }

  .ContinueText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #212121;
    margin: 0;
  }

  .SelectedOptionName {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    color: #9e9e9e;
    min-height: 1.3125rem;
    margin-bottom: 0.25rem;
  }

  .ProductName {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    color: #212121;
    margin-bottom: 0.25rem;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 90%;
    margin-right: 0;
  }
  .ButtonTxt {
    display: flex;
    > p {
      margin: 0;
      font-family: "Open Sans";
      font-style: normal;
      display: flex;
      align-items: center;
      color: #212121;
      font-size: 0.9375rem;
      line-height: 1.25rem;
    }
    > p:first-child {
      font-weight: 600;
      margin-right: 1.25rem;
    }
    > p:last-child {
      font-weight: 700;
    }
  }
}
