.venueContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-size: 1rem;
  max-width: 768px;
  .venueImage {
    height: 14.5rem;
    transition: all 0.25s ease-out;
    position: relative;
    .remote {
      height: 100%;
      width: 100%;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      > svg {
        width: 100%;
        height: 100%;
      }
    }
    .venueSidemenuToggleBtn {
      top: 1.875rem;
      right: 1.875rem;
    }
    .venueBackBtn {
      top: 1.875rem;
      left: 1.875rem;
    }
    .logo {
      > svg {
        transition: all 0.25s ease-out;
        position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        left: 2.5rem;
        top: 8.125rem;
        border-radius: 0.75rem;
        z-index: 2;
      }
    }
  }

  .venueDetails {
    width: 100%;
    left: 0;
    top: 10rem;
    background: #ffffff;
    border-radius: 2.25rem;
    transition: all 0.25s ease-out;
    max-width: 768px;
    .lineBar {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 1rem;
    }
    .share {
      display: flex;
      flex-direction: row;
      position: absolute;
      right: 2.5rem;
      top: 1.25rem;
      font-size: 1rem;
      color: #8d98b7;
      width: 6.25rem;
      height: 1.25rem;
    }
    .venueContent {
      margin: 2.5rem;
      height: calc(100vh - 12.5rem);
      overflow-y: auto;
      overflow-x: unset;
      .venueNameAndStatus {
        display: flex;
        flex-direction: row;
        // margin: 40px 40px 0 40px;
        align-items: center;
        color: #ffdd02;
        > svg {
          height: 1.25rem;
        }
      }
      .addressName {
        margin: 0.25rem 0;
        font-size: 0.9375rem;
        color: #8d98b7;
        display: flex;
        flex-direction: row;
        > svg {
          height: 1rem;
        }
      }

      .shareButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1.6875rem 0;

        > svg {
          height: 2.875rem;
          width: 2.875rem;
          border-radius: 0.625rem;
        }
      }
      .venueInfo {
        margin: 2.875rem 0;
        display: flex;
        justify-content: space-between;
        > svg {
          height: 3.125rem;
          width: 7.875rem;
        }
      }
      .description {
        font-size: 0.9375rem;
        margin: 1.5rem 0;
        > svg {
          height: 4.625rem;
          width: 100%;
          rect {
            width: 100%;
          }
        }
      }
      .orderTypes {
        font-size: 0.9375rem;
        margin: 1.5rem 0;
        > svg {
          height: 1.5rem;
          width: 25%;
          border-radius: 1rem;
          rect {
            width: 100%;
          }
        }
      }

      .AlcoholTextDiv {
        display: flex;
        justify-content: center;
        border-radius: 0.875rem;
        margin-top: 1rem;
        > svg {
          width: 90%;
          height: 4rem;
          border-radius: 0.875rem;
          rect {
            width: 100%;
          }
        }
      }

      .venueTradingTimes {
        margin: 1.5rem 0;
        > svg {
          width: 100%;
          height: 5rem;
          border-radius: 0.875rem;
          rect {
            width: 100%;
          }
        }
      }
    }
  }
  .viewMenuBtnWrapper {
    // background-color: #FFDD02;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.6875rem;
    > svg {
      width: 100%;
      height: 100%;
      rect {
        width: 100%;
      }
    }
  }
}
