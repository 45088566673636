.DifficultTimes {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: #ffffff;
  /* background:#C3E4FF ; */
  box-shadow: 25px 28px 66px rgba(176, 195, 210, 0.727846);
  border-radius: 1.5rem 1.5rem 0px 0px;
  /* padding: 20px 10px; */
}
.DifficultTimes .DifficultTimesText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.625rem;
  text-align: center;
  letter-spacing: -0.025rem;
  color: #334669;
  margin: 0;
}
.DifficultTimes .DiscriptionBold {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9375rem;
  text-align: center;
  color: #6279a2;
  margin: 0;
}
.DifficultTimes .Discription {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  text-align: center;
  color: #334669;
  padding: 0 1.875rem;
  margin-bottom: 8.75rem;
}
.DifficultTimes .TopBar {
  background: #005eb8;
  /* border-radius: 20px 20px 0px 0px; */
  height: 4.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
}

/* .DifficultTimes .TopBar > svg:first-child path {
  fill: #212121 !important;
}
.DifficultTimes .TopBar > svg:last-child path {
  fill: #212121 !important;
}
.DifficultTimes .TopBar > svg:last-child > path:first-child {
  fill: transparent !important;
} */
.DifficultTimes .BarDotsDiv {
  width: 1.5625rem;
  height: 0.4375rem;
  border-radius: 35%;
  background: #85b3ce8c;
  margin: 0 0.4375rem;
  transition: 0.3s all ease-in-out;
}
.DifficultTimes .BarDotsDivSelected {
  background: #234970;
  transition: 0.3s all ease-in-out;
}
.LottieAnimationDiv {
  width: 100%;
  margin: auto;
  max-height: 21.875rem;
  max-width: 25rem;
}
.LottieAnimationDiv div {
  max-height: 21.875rem;
  max-width: 25rem;
}
.DifficultTimes .COVIDTestTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.625rem;
  letter-spacing: -0.01875rem;
  color: #000000;
  text-align: center;
}
.DifficultTimes .COVIDTestDescription {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: #000000;
}

.DifficultTimes .SlideDivs {
  margin-bottom: 12.5rem;
}
.DifficultTimesBtn {
  border-radius: 15px !important;
}
.DifficultTimesBtn p {
  color: #ffffff !important;
}

@media only screen and (max-height: 750px) {
  .DifficultTimes .COVIDTestTitle {
    font-size: 1.125rem;
  }
  .DifficultTimes .COVIDTestDescription {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  .DifficultTimes .DifficultTimesText {
    font-size: 1.125rem;
  }
  .LottieAnimationDiv {
    width: 80%;
    margin: auto;
  }
  .DifficultTimes .Discription {
    margin-bottom: 7.5rem;
    font-size: 0.875rem;
  }

  .DifficultTimes .SlideDivs {
    margin-bottom: 6.25rem;
  }

  .DifficultTimes .YellowButtons {
    height: 2.5rem !important;
  }

  .DifficultTimes .TopBar {
    height: 3.125rem !important;
  }
  .DifficultTimes .TopBar img {
    max-height: 2.1875rem;
  }
}
