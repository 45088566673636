.subCategoryDescription {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #212121;
  padding: 0.25rem 2.1875rem;
  max-height: 15rem;
  text-overflow: initial;
  white-space: normal;
}

.isScrolled {
  max-height: 0;
  transition: max-height 500ms linear;
}

.isScrolledOut {
  max-height: 15rem;
  transition: max-height 500ms linear;
}
