@mixin border-bottom() {
  border-bottom: 1px solid #e8eaf4;
}

$yellow-filters-color: #ffdd02;

.FilteringPage {
  .HeaderDiv {
    @include border-bottom();
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 2rem;
    .HeaderTitle {
      width: 100%;
      text-align: center;
      font-family: Open Sans;
      font-size: 1.1875rem;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      margin: 0;
      color: #737fa4;
    }
  }

  .FiltersListDiv {
    padding: 1.875rem 2.1875rem;
  }

  .lineBar {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 1rem;
    > span {
      width: 2.625rem;
      background-color: #e8eaf4;
      height: 0.3125rem;
      position: absolute;
      top: 0.625rem;
    }
  }

  .FilterDiv {
    padding: 1.1875rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include border-bottom();
    > p {
      font-family: Open Sans;
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 400;
      text-align: left;
      color: #212121;
      margin: 0;
    }
  }

  .ConfirmButtonDiv {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(52, 53, 66, 0.12);
    width: 100%;
    height: 6rem;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.ant-checkbox) {
    width: 1.625rem;
    height: 1.625rem;
    background: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 0.375rem;
  }

  :global(.ant-checkbox-wrapper) {
    width: 1.625rem;
    height: 1.625rem;
    background: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 0.375rem;
  }
  :global(.ant-checkbox-inner) {
    width: 1.625rem;
    height: 1.625rem;
    background: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 0.375rem;
  }

  :global(.ant-checkbox-checked::after) {
    border-color: $yellow-filters-color !important;
  }

  :global(.ant-checkbox-inner:focus) {
    border-color: $yellow-filters-color !important;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    border-color: $yellow-filters-color !important;
    background-color: $yellow-filters-color !important;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
    width: 6px;
    height: 12px;
    border-color: #212121 !important;
    top: 42% !important;
    left: 25% !important;
  }

  :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
    border-color: $yellow-filters-color !important;
  }
}
