.OrderTypeButtonsDiv {
  background: #faf6e0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  min-height: 50px;
  height: auto;
  padding: 0 15px;
  margin: 4px 0;
  border: 1px solid #ffdd02;
}

.OrderTypeButtonsDiv .ChangeButton {
  background: rgba(255, 221, 2, 0.3);
  border-radius: 0.5rem;
  padding: 0 0.6rem;
  height: 38px;
}
.OrderTypeButtonsDiv .ChangeButton p {
  font-family: Open Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.OrderTypeButtonsDiv .OrderTypeButtonsText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #212121;
  text-align: center;
  margin: 0;
  width: 100%;
}

.SelectedDot {
  background: #ffdd02;
  border-radius: 12px;
  width: 4px;
  float: right;
  height: 20px;
}

.OrderTypeButtonsDivSelected .OrderTypeButtonsText {
  font-weight: 600 !important;
}
.OrderTypeButtonsDiv .EtaText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.0625rem;
  text-align: left;
  color: #212121;
  margin: 0;
}

.OrderTypeButtonsDivSelected {
  background: #faf6e0 !important;
  border: 1px solid #ffdd02 !important;
}

.SelectedAddressText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #616161;
  margin: 0;
}
