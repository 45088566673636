.TipCards{
    /* border: 2px solid rgba(249, 191, 0, 0.35); */
    box-sizing: border-box;
    border-radius: 8px;
    width: 4.5rem;
    height: 2.25rem;
display: flex;
align-items: center;
justify-content: center;
margin: 0 3px;
box-sizing: border-box;
background-color: #F9F9F9;
}

.SelectedTipCard{
    box-sizing: border-box;
    border-radius: 8px;
    width: 4.5rem;
    height: 2.25rem;
display: flex;
align-items: center;
justify-content: center;
margin: 0 3px;
box-sizing: border-box;
    border-radius: 5px;
    background: rgba(255, 221, 2, 0.1) !important;
border: 1px solid #FFDD02 !important;
}

.TipCards p{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 0.875rem;
line-height: 19px;
text-align: center;

color: #212121;
margin: 0;
}


.SelectedTipCard p{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 19px;
    text-align: center;
    color: #212121;
    margin: 0;
}