.SideMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #2a2a2f;
  box-sizing: border-box;
  overflow: auto;
  padding: 1.875rem 2.5rem 0 3.125rem;

  .YoelloLogo {
    height: 2.375rem;
  }

  .ScanTitleText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: left;
    color: #ffdd02;
  }

  .SignInText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    text-align: left;
    color: #ffffff;
  }

  .MenuItemDiv {
    padding: 0.75rem 0;
    margin: 0.375rem 0;
    display: flex;
    align-items: center;

    .SVGsDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      min-height: 1.5625rem;
      min-width: 1.5625rem;
      // margin-left: 1.1rem;
    }
  }
  .Header {
    background: #2a2a2f;
    padding: 16px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .Content {
    margin-top: 120px;
    margin-bottom: 180px;
  }
  .VenueHeader {
    > img {
      width: 4rem;
      height: 4rem;
      border-radius: 0.75rem;
    }
    > svg {
      width: 4rem;
      height: 4rem;
      border-radius: 0.75rem;
    }
    > p {
      padding-top: 1rem;
      color: white;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  .LanguageWrapper {
    margin: 0 30px 12px;
  }
  .MenuItemName {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .BorderLine {
    border: 1px solid #424242;
    width: 70%;
    margin: 0 30px 1.4rem;
  }

  .UserName {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin: 0;
  }

  .SignOut {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9375rem;
    color: #ffffff;
    margin-bottom: 0;
    // margin-top: auto;
    margin-left: 0.8rem;
  }
  .PoweredBy {
    background: #2a2a2f;
    padding: 12px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 0.1rem;
    > p {
      font-size: 0.9375rem;
      font-family: Open Sans;
      color: white;
    }
  }
  .SignInButtonText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #212121;
    text-align: center;
    margin: 0;
  }

  .SignInButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffdd02;
    border-radius: 0.625rem;
    width: 7.75rem;
    height: 2.75rem;
  }

  .AppVersion {
    color: #757575;
    font-size: 0.75rem;
  }

  :global(.flag-select__option__icon) {
    top: -1px !important;
    padding: 0;
    border-radius: 0.5rem;
  }
  :global(.flag-select__btn) {
    padding: 0;
  }
  :global(.flag-select__option__label) {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
  }
}
