$OpenColor: #65ff78;
$CloseColor: #ff658b;
.venueCardContainer {
  height: auto;
  display: flex;
  align-items: flex-start;
  // justify-content: space-between;
  background: #f9f9f9;
  border-radius: 1rem;
  padding: 0.75rem;
  margin-bottom: 0.9375rem;
  position: relative;
  > div:first-child {
    display: flex;
    width: 90%;
    align-items: center;
    .venueImage {
      border-radius: 0.875rem;
      position: relative;
      max-width: 4.25rem;
      max-height: 4.25rem;
      min-width: 4.25rem;
      min-height: 4.25rem;
      margin-right: 1.3rem;
      > img {
        border-radius: 0.875rem;
      }
    }
    .venueDetails {
      position: relative;
      padding-left: 0.575rem;
      display: flex;
      flex-direction: column;
      > p:first-child {
        height: fit-content;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        display: flex;
        align-items: center;
        color: #212121;
        margin: 0;
        margin-bottom: 0.75rem;
      }
      .venueOpenText {
        display: flex;
        align-items: center;
        > p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 0.9375rem;
          line-height: 1.25rem;
          color: #2deb62;
          margin: 0;
          padding-left: 0.5625rem;
        }
        .openCircleRipple {
          background-color: #35ffc3;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          animation: GreenRipple 0.7s linear infinite;
        }
      }
      .venueCloseText {
        display: flex;
        align-items: center;
        > p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 0.9375rem;
          line-height: 1.25rem;
          color: #ff4f4f;
          margin: 0;
          padding-left: 0.5625rem;
        }
        .closeCircleRipple {
          background-color: #ff3578;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          animation: RedRipple 0.7s linear infinite;
        }
      }
    }
  }

  .letsGo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    position: absolute;
    right: 0.9375rem;
    top: 2.6875rem;
    > svg {
      height: 1rem;
      width: 0.5625rem;
    }
  }
}
@keyframes GreenRipple {
  0% {
    box-shadow: 0 0 0 0 rgba($OpenColor, 0.3), 0 0 0 0.1em rgba($OpenColor, 0.3),
      0 0 0 0.3em rgba($OpenColor, 0.3), 0 0 0 0.5em rgba($OpenColor, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.1em rgba($OpenColor, 0.3),
      0 0 0 0.3em rgba($OpenColor, 0.3), 0 0 0 0.5em rgba($OpenColor, 0.3),
      0 0 0 0.8em rgba($OpenColor, 0);
  }
}
@keyframes RedRipple {
  0% {
    box-shadow: 0 0 0 0 rgba($CloseColor, 0.3),
      0 0 0 0.1em rgba($CloseColor, 0.3), 0 0 0 0.3em rgba($CloseColor, 0.3),
      0 0 0 0.5em rgba($CloseColor, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.1em rgba($CloseColor, 0.3),
      0 0 0 0.3em rgba($CloseColor, 0.3), 0 0 0 0.5em rgba($CloseColor, 0.3),
      0 0 0 0.8em rgba($CloseColor, 0);
  }
}
