.ProductCard {
  background: #f9f9f9;
  border-radius: 0.625rem;
  height: 5.45rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  margin: 0.625rem 1.25rem;
  justify-content: space-between;
  position: relative;
  .DiscountIcon {
    position: absolute;
    top: 0.125rem;
    right: 0.625rem;
  }
}

.ProductCardDisabled {
  background-color: rgba(138, 138, 138, 0.66) !important;
  opacity: 0.8;
}

.ProductCard .ImageDiv {
  border-radius: 0.625rem;
}
.ProductCard .productPrice {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProductCard .cardImage img {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 0.75rem;
}
.ProductCard .cardImage div {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 0.75rem;
  background-color: #ffffff;
}

.ProductCard .Title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: #212121;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ProductCard .Description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #757575;
}

.ProductCard .OldPrice {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-decoration-line: line-through;
  color: #000000;
  margin: 0;
  padding-right: 1rem;
}

.ProductCard .OutOfStuckText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  text-align: left;
  color: #9a2929;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.productPrice {
  // padding: 2.625rem 1.125rem 0.5625rem 0;
  .CurrentPrice {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    display: flex;
    align-items: flex-end;
    color: #000000;
    margin-bottom: -1.875rem;
  }
}

.ProductCard {
  .cardImage {
    margin-right: 0.8125rem;
  }
  .flexDirecton {
    flex-direction: row !important;
    > div:first-child {
      display: flex;
      align-items: center;
      width: 100% !important;
      > p {
        margin-bottom: 0 !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .productDetails {
    display: flex;
    flex-direction: column;
    margin-right: -3rem;
    // padding-left: 0.6875rem;
    // padding-right: 0.8125rem;
    > div:first-child {
      // margin-bottom: 0.625rem;
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.25rem;
        color: #212121;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0.5rem;
      }
    }
    > div:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      > div:first-child {
        > p:first-child {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 0.8125rem;
          line-height: 1.25rem;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #757575;
        }
        // >p:last-child{
        //   font-family: Open Sans;
        //   font-style: normal;
        //   font-weight: 600;
        //   font-size: 0.9375rem;
        //   line-height: 1.25rem;
        //   color: #212121;
        //   margin: 0;
        // }
      }
    }
  }

  > div:last-child {
    margin-top: 1rem;
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5625rem;
      display: flex;
      align-items: center;
      text-align: right;
      color: #212121;
      // position: absolute;
      bottom: 2.25rem;
      right: 0.5625rem;
    }
  }
  .PlusIconDiv {
    width: 24px;
    height: 24px;
    background: #ffdd02;
    border-radius: 8px;
    // position: absolute;
    bottom: 0.575rem;
    right: 0.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-top: 0.5125rem;
  }
}
@media only screen and (max-width: 375px) {
  .ProductCard {
    height: 6.25rem;
  }
}
@media only screen and (max-width: 321px) {
  .ProductCard {
    height: 7.25rem;
  }
}
