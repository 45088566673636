.ProductShopCardContainer {
  background: #f9f9f9;
  border-radius: 1rem;
  padding: 0.75rem;
  max-height: 500px;
  > div:first-child {
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      width: 100%;
      .productImage {
        border-radius: 0.875rem;
        position: relative;
        max-width: 4.25rem;
        max-height: 4.25rem;
        min-width: 4.25rem;
        min-height: 4.25rem;
        margin-right: 0.6875rem;
        > img {
          border-radius: 0.875rem;
        }
      }
      .productDetails {
        display: flex;
        flex-direction: column;
        padding-right: 0.8125rem;
        // width: calc(100% - 68px);
        > div:first-child {
          > p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.375rem;
            color: #212121;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0.125rem;
          }
        }
        > div:last-child {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          > div:first-child {
            > p:first-child {
              font-family: Open Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 0.8125rem;
              line-height: 1.125rem;
              color: #757575;
              margin: 0;
              margin-bottom: 0.1875rem;
            }
            > p:last-child {
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 0.9375rem;
              line-height: 1.25rem;
              color: #212121;
              margin: 0;
            }
          }
          > div:last-child {
            .controllers {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 0.25rem;
              border-radius: 0.625rem;
              padding: 0.5rem;
            }
          }
        }
      }
    }
  }
  > div:last-child {
    > div:first-child {
      margin-top: 1.1875rem;
    }
    .description {
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      > span {
        width: 0.25rem;
        height: 0.25rem;
        background: #424242;
        border-radius: 100%;
      }
      > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: #424242;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        margin-left: 0.5rem;
      }
    }
  }
}
