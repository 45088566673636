.settingsPageContainer {
  width: 100%;
  height: 100vh;
  max-width: 768px;
  position: relative;
  background-color: #ffffff;
  margin: auto;
  overflow: hidden;
  .header {
    width: 100%;
    max-width: 768px;
    display: flex;
    border-bottom: 1px solid #e8eaf4;
    flex-direction: row;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    .pageTitle {
      width: 100%;
      margin-top: 2.5rem;
      padding-bottom: 1.5625rem;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;
      > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.6875rem;
        display: flex;
        align-items: center;
        color: #212121;
        margin: 0;
        width: 10rem;
        text-align: center;
      }
      > svg {
        margin-right: 0.625rem;
        width: 1rem;
        height: 1.125rem;
      }
    }
    .backBtn {
      top: 1.875rem;
      left: 1.875rem;
      > svg {
        > path {
          fill: #000000;
        }
      }
    }
  }
  .lottieLoading {
    background: unset;
    height: calc(100vh - 7.6875rem);
    background: unset;
    height: calc(100vh - 7.6875rem);
    align-items: center;
    display: flex;
    > div {
      width: 6.25rem !important;
      height: 6.25rem !important;
    }
  }
  .noOrdersText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    color: #000000;
    text-align: center;
  }
  .orderList {
    padding: 1.875rem 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 5.45rem);
    position: relative;
    margin-bottom: 5rem;
    margin-top: 5rem;
    padding-top: 1.2rem;
    .historyList {
      padding-bottom: 10rem;
      > div > div:last-child {
        margin-bottom: 5.5rem;
        padding-bottom: 5.5rem;
      }
    }
    // padding-bottom: 75px;
  }
}

.contentSlideToTop {
  .shadowTop {
    height: 6.25rem;
    background-color: #fff;
    position: fixed;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    border-radius: 1.25rem;
    left: 0;
    top: 5.25rem;
    z-index: 1;
  }
}
.contentSlideToBottom {
  .shadowBottom {
    height: 6.25rem;
    background-color: #fff;
    position: fixed;
    bottom: 1.8rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    border-radius: 1.25rem;
    width: 100%;
    left: 0;
  }
}

.OrderReviewContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-top: 5rem;
  padding-top: 1.2rem;
  position: relative;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
    overflow: auto;
    position: fixed;
    top: 5rem;
  }
}
