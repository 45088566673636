.selectLanguage {
  > div {
    display: flex;
    align-items: flex-end;
    > svg {
      max-width: 1.25rem;
      min-width: 1.25rem;
      max-height: 1.25rem;
      min-height: 1.25rem;
    }
    > p {
      margin: 0;
      padding-left: 0.3125rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    > span {
      color: #ffffff;
      font-size: 1rem;
    }
  }
}
