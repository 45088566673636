.ShareButton {
  /* background: #000000; */
  border-radius: 15.3281px;

  width: 2.875rem;
  height: 2.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ShareButtonDisabled {
  /* background: #4c4646ee !important; */
  opacity: 0.6;
  pointer-events: none;
}
.ShareButton img {
  max-width: 1.75rem;
  max-height: 1.75rem;
}

.ShareButtonSideMenu {
  width: 3.125rem !important;
  height: 3.125rem !important;
}

@media only screen and (max-width: 350px) {
  .ShareButton {
    width: 2.5rem;
    height: 2.5rem;
  }
  .ShareButton img {
    max-width: 0.9375rem;
    max-height: 0.9375rem;
  }

  .ShareButtonSideMenu {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}
@media only screen and (max-width: 350px) {
  .ShareButtonSideMenu {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}
