.qrCodeContainer {
  width: 100%;
  height: 100%;
  border-radius: 2.25rem 2.25rem 0 0 !important;
  padding: 0.9375rem 1.875rem;
  overflow: auto;
  .Content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .InfoText {
      width: 75%;
      text-align: center;
      color: #757575;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
      font-weight: 600;
    }
    .TopText {
      display: flex;
      flex-direction: column;
      text-align: center;
      .QRSubtitle {
        // width: 75%;
        // line-height: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        // margin-bottom: 1rem;
      }
      .CollectionPoint {
        font-size: 1.25rem;
      }
    }
    .QRCode {
      position: relative;
    }
    .TextContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 0.5rem;

      > div {
        align-items: center;
      }
    }
  }
}

.TopBar {
  margin-top: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  > div:first-child {
    width: 75%;
    display: flex;
    > svg {
      margin-right: 1rem;
    }
    > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      align-items: center;
      color: #212121;
      margin-bottom: 0;
    }
  }
}
.CloseButton {
  backdrop-filter: none;
  color: rgb(170, 182, 211);
  font-size: 2rem;
}
.lineBar {
  width: 2.625rem;
  height: 0.3125rem;
  background: #e8eaf4;
  border-radius: 2.5625rem;
  margin: auto;
}
